import { Row, Col } from "antd";

import { UserService } from "../../services/UserService";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SwitchCard from "../../components/device/SwitchComponent";
import { User } from "../../storage/store";
import Main from "../../components/layout/Main";
import SensorCard from "../../components/device/sensorDevice";
// import ToggleTop from "../../components/common/ToggleTop";
import { useUser } from "../../Context/context";
import ControllerCard from "../../components/device/ControllerDevice";
import { CONTROLLERS, SENSORS } from "./Home";
import { useToggleTop } from "../../components/common/ToggleTop";
import CircleBarSpinner from "../../components/common/CircleBarSpinner";

function Room() {
  const location = useLocation();
  const param = location.state.params;
  const [configDevices, setConfigDevices] = useState(null);
  const [devices, setDevices] = useState(null);
  const [editOption, setEditOption] = useState(false);
  const [updatedRoomName, setUpdatedRoomName] = useState('');
  // const [updateMsg, setUpdateMsg] = useState('none');
  const [RoomName, setRoomName] = useState(param.roomName);
  const [trigger, setTrigger] = useState(0);

  const { getRooms, floor, setFloor } = useUser();

  const setToggleTop = useToggleTop();

  function getLastestate(configDev, floorId, roomId) {
    const PropertyId = User.getPropertyId();
    console.log(configDev);
    async function getRoomDevices() {
      try {
        const lastStates = await UserService.getLastStateByFloorRoomId(
          PropertyId,
          floorId,
          roomId
        );
        if (lastStates) {
          console.log(configDev);
          console.log(lastStates);
          let allRoomDevices = configDev;
          allRoomDevices.forEach((data, index) => {
            if (
              lastStates[0].devices[data.applianceId] &&
              "speed" in lastStates[0].devices[data.applianceId]
            ) {
              data.props = {
                state: lastStates[0].devices[data.applianceId]
                  ? lastStates[0].devices[data.applianceId].state
                  : false,
                speed: lastStates[0].devices[data.applianceId]
                  ? lastStates[0].devices[data.applianceId].speed
                  : 3,
                timeStamp: lastStates[0].devices[data.applianceId]
                  ? lastStates[0].devices[data.applianceId].timeStamp
                  : null,
              };
            } else if (
              lastStates[0].devices[data.applianceId] &&
              "value" in lastStates[0].devices[data.applianceId]
            ) {
              data.props = {
                state: lastStates[0].devices[data.applianceId]
                  ? lastStates[0].devices[data.applianceId].state
                  : false,
                value: lastStates[0].devices[data.applianceId]
                  ? lastStates[0].devices[data.applianceId].value
                  : "",
                timeStamp: lastStates[0].devices[data.applianceId]
                  ? lastStates[0].devices[data.applianceId]?.timeStamp
                  : null,
                ...lastStates[0].devices[data.applianceId],
              };
            } else {
              data.props = {
                state: lastStates[0].devices[data.applianceId]
                  ? lastStates[0].devices[data.applianceId].state
                  : false,
                timeStamp: lastStates[0].devices[data.applianceId]
                  ? lastStates[0].devices[data.applianceId]?.timeStamp
                  : null,
                ...lastStates[0].devices[data.applianceId],
              };
            }
          });
          const roomDevices = allRoomDevices.filter((data) => {
            return (
              data.floorId === floorId && data.roomId === roomId
            );
          });
          setDevices(roomDevices);
        }
      } catch (error) {
        console.log("error...", error);
        /* by default set all devices default values*/
        let allRoomDevices = configDev;
        const roomDevices = allRoomDevices.filter((data) => {
          return (
            data.site === param.floorName && data.location === RoomName
          );
        });
        setDevices(roomDevices);
      }
    }
    getRoomDevices();
  }
  function updateRoom(data, roomId, newData) {
    data.floors = data.floors.map(floor => {
        floor.rooms = floor.rooms.map(room => {
            if (room._id === roomId) {
                return {...room, ...newData};  // Spread syntax to merge old room data with newData
            }
            return room;
        });
        return floor;
    });
  }
  async function getConfig() {
    try {
      const PropertyId = User.getPropertyId();
      const result = await UserService.getConfig(PropertyId);
      let roomId, floorId, roomName, floorName;
      if (result) {
        const data = result.floors.filter(item=>item.rooms.filter(item1=>{
          if(item1._id===param.roomId){
            roomId=item1._id;
            floorId = item._id;
            roomName = item1.name;
            floorName = item.name;
            console.log(roomId, floorId, roomName, floorName);
          }
        }));
        setRoomName(roomName);
        console.log(roomName, floorName);
        setConfigDevices(result.devices);
        getLastestate(result.devices, floorId, roomId);
        console.log(result.devices);
      }
    } catch (error) {
      console.log("error...", error);
    }
  }

 async function handleRoomNameChange(){
  console.log(updatedRoomName);
    if (updatedRoomName === "") {
      window.alert(`Room name not changed`);
      setToggleTop("Room name not updated", "red");
      // setTimeout(()=>{setUpdateMsg('none'); setUpdatedRoomName('')},3000);

      return null;
    }
    if(updatedRoomName!=="" && window.confirm(`Do you want to update your room name to "${updatedRoomName}"?`)!==false){
      // setUpdateMsg('red');
      // setTimeout(()=>setUpdateMsg('none'),3000)
      const propertyid = User.getPropertyId();
      var config = await UserService.getConfig(propertyid);
      if(config){
        console.log(config);
        updateRoom(config, param.roomId, {name :updatedRoomName});
        try {
          const response = await UserService.updateConfig(
            config,
            propertyid
          );
          console.log(JSON.stringify(response));
          setToggleTop("Room name updated successfully", "green")
          // setRoomName('')
          getConfig();
        } catch (error) {
          setToggleTop("Room name not updated", 'red');
        }
      }
    }else{
      setToggleTop("Room name not updated", 'red');
      // setTimeout(()=>{ setUpdatedRoomName('')},3000)
    }
    // console.log(updatedRoomName);
  }
 

  useEffect(() => {
    getConfig();
  }, [trigger]);
  if (devices) {
    return (
      <>
        {/* {updateMsg==='green'? <ToggleTop color='green' message="Room name updated successfully" toggle={setUpdateMsg}/>
          : updateMsg==='red' ? <ToggleTop color='red' message="Room name not updated" toggle={setUpdateMsg}/>
            : null
        } */}
        <Main switchProperty={false}>
          <div className="tabled">
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 20px' }}>
              {editOption === true
                ?
                <input
                  style={{
                    outline: 'none',
                    padding: '5px',
                    borderRadius: '10px',
                    border: 'none',
                    transition: 'all 2s',
                    color: 'grey',
                    fontSize: '1.2em',
                    fontWeight: '500',
                    boxShadow: '0px 0px 5px grey'
                  }}
                  autoCorrect="off"
                  type="text"
                  defaultValue={RoomName}
                  autoFocus
                  onChange={(e)=>setUpdatedRoomName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      // setFloorName("");
                      e.preventDefault();
                      e.stopPropagation();
                      setEditOption(false);
                    }
                  }}
                />
                :
                <div style={{ fontSize: '1.2em', fontWeight: '500', padding: '5px 2px' }}>{RoomName}</div>}
              {/* <div style={{textAlign:'center', fontSize:'1.3em', fontWeight:'100'}}>{RoomName}</div> */}
              {editOption === true
                ?
                <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                  <button
                    type='button'
                    style={{ cursor: 'pointer', border: 'none', backgroundColor: 'transparent', color: 'rgba(0, 115, 174, 1)', maxHeight: '30px' }}
                    onClick={(e) => {
                      e.preventDefault(); 
                      setEditOption(null);
                      handleRoomNameChange();
                  }}>update</button>
                </div>
                :
                <button type='button'
                  style={{
                    cursor: 'pointer',
                    border: 'none',
                    backgroundColor: 'transparent',
                    color: 'black',
                    padding: '2px 10px',
                    maxHeight: '30px'
                  }}
                  onClick={(e) => {
                    // setFloorName("");
                    // e.preventDefault();
                    // e.stopPropagation();
                    setEditOption(true);
                  }}>Edit</button>}
            </div>
            {console.log(devices)}
            <Row gutter={[16, 16]}>
              {devices
                ? devices.map((data, index) => (
                  <Col key={index+700} gutter={6}>
                    {SENSORS.includes(data.meta.model)
                      ? <SensorCard props={data} stateRefresh={setTrigger}/>
                        : CONTROLLERS.includes(data.meta.model)
                          ? <ControllerCard props={data} stateRefresh={setTrigger}/> 
                            : <SwitchCard props={data} stateRefresh={setTrigger}/>}
                  </Col>
                ))
                : null}
            </Row>
          </div>
        </Main>
      </>
    );
  } else {
    return (
      <>
        <Main>
          {/* <div className="tabled">
            <Row gutter={[16, 0]}>
              <div>
                <p>No Devices !</p>
              </div>
            </Row>
          </div> */}
          <CircleBarSpinner/>
        </Main>
      </>
    );
  }
}

export default Room;
