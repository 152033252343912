import React from 'react'
import { useUser } from '../../Context/context'

const Info = () => {
  // console.log(device)
  const {device} = useUser();
  return (
    <div style={{padding:'10px 0px 0px 20px'}}>
      <h2>{device.name}</h2>
      <p>
        <span>Device ID :</span>
        <span style={{ fontWeight: '800', fontSize: '1.1em' }}>
          {device.deviceId}
        </span>
      </p>

      <p>
        <span>
          Model :
        </span>
        <span style={{ fontWeight: '800', fontSize: '1.1em' }}>
          {device.meta.model}
        </span>
      </p>

      <p>
        <span>
          Build :
        </span>
        <span style={{ fontWeight: '800', fontSize: '1.1em' }}>
          {device.build ?? null}
        </span>
      </p>

      <p>
        <span>
          Firmware Version :
        </span>
        <span style={{ fontWeight: '800', fontSize: '1.1em' }}>
          {device.firmwareVersion ?? null}
        </span>
      </p>

      <p>
        <span>
          Vendor ID :
        </span>
        <span style={{ fontWeight: '800', fontSize: '1.1em' }}>
          {'001'}
        </span>
      </p>

      <p>
        <span>
          Signal Strength(dBm) :
        </span>
        <span style={{ fontWeight: '800', fontSize: '1.1em' }}>
          {device.rssi ?? null}
        </span>
      </p>
    </div>
  )
}

export default Info