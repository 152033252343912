import React, { useEffect, useState } from 'react'

const ToggleSwitch = ({id, onChange, checked, color, backgroundColor}) => {
    color = color ?? backgroundColor ?? 'skyblue';
    onChange = onChange ?? onChanageSubstute;

    function onChanageSubstute(){
        console.log(isCheked);
    }
    const [isCheked, setIsChecked] = useState();
    useEffect(()=>setIsChecked(checked), [checked]);
    return (
        <>
            <label className="toggle-switch">
                <input id={id} type="checkbox" onChange={onChange} checked={checked}/>
                <span className="toggle-slider round" 
                    style={{
                        backgroundColor: isCheked ? color : '#ccc'
                    }}
                ></span>
            </label>
        </>
    )
}

export default ToggleSwitch