import React from 'react'

const CurtainDoubleIcon = ({color, height, width}) => {
    return (
        <svg width={width ?? "39"} height={height ?? "36"} viewBox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39 3.42857H0V0H39V3.42857ZM1.77273 36H8.86364C8.86364 30.8571 5.31818 27.4286 5.31818 27.4286C15.9545 20.5714 17.7273 5.14286 17.7273 5.14286H1.77273V36ZM37.2273 5.14286H21.2727C21.2727 5.14286 23.0455 20.5714 33.6818 27.4286C33.6818 27.4286 30.1364 30.8571 30.1364 36H37.2273V5.14286Z" fill={color} />
        </svg>
    )
}

export default CurtainDoubleIcon