/*!
 */

// import { useState } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { IoHome, IoPlayCircle, IoSettingsSharp } from "react-icons/io5";
import { BsGrid1X2Fill } from "react-icons/bs";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>HASHh Dashboard</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/Dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              <BsGrid1X2Fill style={styles.icon} />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/Property">
            <span
              className="icon"
              style={{
                background: page === "property" ? color : "",
              }}
            >
              <IoHome style={styles.icon} />
            </span>
            <span className="label">Property</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/Routines">
            <span
              className="icon"
              style={{
                background: page === "routine" ? color : "",
              }}
            >
              <IoPlayCircle style={styles.icon} />
            </span>
            <span className="label">Routines</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/RuleEngine">
            <span
              className="icon"
              style={{
                background: page === "ruleEngine" ? color : "",
              }}
            >
              <IoSettingsSharp style={styles.icon} />
            </span>
            <span className="label">Rule Engine</span>
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item className="menu-item-header" key="5">
          Account
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/Profile">
            <span
              className="icon"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
              {profile}
            </span>
            <span className="label">Profile</span>
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item key="7">
          <NavLink to="/sign-in">
            <span className="icon">{signin}</span>
            <span className="label">Sign In</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/sign-up">
            <span className="icon">{signup}</span>
            <span className="label">Sign Up</span>
          </NavLink>
        </Menu.Item> */}
      </Menu>
      {/* <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <span className="icon" style={{ color }}>
            {dashboard}
          </span>
          <h6>Need Help?</h6>
          <p>Please check our docs</p>
          <Button type="primary" className="ant-btn-sm ant-btn-block">
            DOCUMENTATION
          </Button>
        </div>
      </div> */}
    </>
  );
}

const styles = {
  icon: {
    width: 20,
    height: 20,
  }
}

export default Sidenav;
