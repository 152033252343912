import React from 'react'

const GateOpenIcon = () => {
    return (
        <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.5455 52V29H29.3636V35.5714H26.1818V32.2857H23V52H26.1818V48.7143H29.3636V52H32.5455ZM26.1818 45.4286V38.8571H29.3636V45.4286H26.1818ZM54.8182 32.2857V35.5714H51.6364V29H48.4545V52H51.6364V48.7143H54.8182V52H58V32.2857H54.8182ZM54.8182 45.4286H51.6364V38.8571H54.8182V45.4286Z" fill="black" />
        </svg>
    )
}

export default GateOpenIcon