import React from 'react'
import { IoCloseOutline } from 'react-icons/io5';
import Hr from './Hr';

const BottomSheet = ({ children, drawer, setDrawer, background, title }) => {

    if (drawer === 'close') setTimeout(() => setDrawer(''), 800);

    return (
        <>
            {drawer !== '' && (
                <div className={(drawer === 'open' || drawer === '') ?
                    `bottomSheet-overlay bottomSheet-overlay-animation-open` :
                    (drawer === 'close' || drawer === '') ?
                        `bottomSheet-overlay bottomSheet-overlay-animation-close` :
                        `bottomSheet-overlay`
                } onClick={() => setDrawer('close')}>

                    {/* drawer */}

                    <div style={{ backgroundColor: (background ?? "#FFFFFFF") }}
                        className={`bottomSheet-main ${drawer === 'open' ? 'bs-open' : drawer === 'close' ? 'bs-close' : null}`}
                        onClick={(e) => e.stopPropagation()}>

                        {/* Drawer Content */}
                        {children}
                        <div style={{height:'100px'}}></div>
                    </div>

                </div>
            )}
        </>
    )
}

export default BottomSheet


{/* <div
            style={{ backgroundColor: (background ?? "#FFFFFFF") }}
            className={`bottomSheet-main ${drawer === 'open' ? 'bs-open' : drawer === 'close' ? 'bs-close' : null}`}
            onClick={(e) => e.stopPropagation()}
          >
           <div style={{
            display:'flex',
            alignItems:'center',
            
            // border:'1px solid red',
           }}>
            <div className='sd-close-icon'style={{
            // border:'1px solid yellow'
              }} >
                <IoCloseOutline onClick={() => setDrawer('close')} style={{ fontSize: '30px', color: 'grey', cursor: 'pointer' }} />
              </div>
              <p style={{
            height:'100%', marginTop:'15px', fontSize:'20px'
              }}>{title}</p>
           </div>
            
            <Hr color={'grey'} size={'0.5px'} margin={'5px 0'}/>
            <main className="sideDrawer-content">
              {children}
            </main>
          </div>  */}