import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Home/Profile";
import SignUp from "./pages/SignUp";
import Login from "./auth/LoginView";
import Room from "./pages/Home/Room";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import SelectEcoSystem from "./pages/SelectEcoSystem";
import Welcome from "./pages/Welcome";
import Property from "./pages/Home/Property";
import Routines from "./pages/Home/Routines/Routines";
import RuleEngine from "./pages/Home/RuleEngine";
import SwitchCard from "./components/device/SwitchComponent";
import SwitchDetail from "./components/device/SwitchDetails";
import SensorDetails from "./components/device/sensorDetails";
import BottomSheet from "./pages/Home/Routines/BottomSheet";
import ControllerDetail from "./components/device/ControllerDetail";
// import './styles/fonts.css';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/sign-up" exact component={SignUp} />
        <Route exact path="/SelectEcoSystem" component={SelectEcoSystem} />
        <Route exact path="/Welcome" component={Welcome} />
        <Route exact path="/Login" component={Login} />
        <Route exact path="/Property" component={Property} />
        <Route exact path="/Routines" component={Routines} />
        <Route exact path="/RuleEngine" component={RuleEngine} />
        <Route exact path="/Room" component={Room} />
        <Route exact path="/dashboard" component={Home} />
        <Route exact path="/tables" component={Tables} />
        <Route exact path="/billing" component={Billing} />
        <Route exact path="/rtl" component={Rtl} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/switchCard" component={SwitchCard} />
        <Route exact path="/switchDetail" component={SwitchDetail} />
        <Route exact path="/SensorDetail" component={SensorDetails} />
        <Route exact path="/controllerDetail" component={ControllerDetail}/>
        <Redirect from="*" to="/Welcome" />
      </Switch>
    </div>
  );
}

export default App;
