import React from 'react'

const Hr = ({color, size, margin}) => {
    color = color ?? '#D3D3D3';
    size = size ?? '0.5px';
    margin = margin ?? '12px 0'
  return (
    <div 
        style={{
            borderTop: `${size} solid ${color}`,
            margin: margin
        }} 
        />
  )
}

export default Hr