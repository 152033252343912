import React from 'react'
import { useUser } from '../../Context/context'

const SwitchInfo2 = ({ls}) => {
    const {device,setDevice} = useUser();
    console.log(device);
    console.log(ls);
  return (
    <div
        style={{
            padding:'10px 20px',
            display:'flex',
            flexDirection:'column',
            gap:'20px'
        }}
    >
        <div>Device ID : <b> {device.deviceId}</b> </div>
        <div> Model : <b>{device.meta.model}</b> </div>
        <div>Build :  <b>{device.build}</b> </div>
        <div>Firemware Version :  <b>{device.firmwareVersion}</b></div>
        <div>Vendor ID :  <b>{device.meta.vendorId}</b></div>
        <div>Signal Strength(dbm) : </div>
    </div>
  )
}

export default SwitchInfo2