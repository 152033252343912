import React from 'react'

const PauseIcon = ({color, height, width}) => {
    return (
        <svg width={width ?? "26"} height={height ?? "37"} viewBox="0 0 26 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.80412 33.6825C7.80412 34.3696 7.53115 35.0286 7.04525 35.5145C6.55935 36.0004 5.90033 36.2734 5.21317 36.2734H2.62221C1.93504 36.2734 1.27602 36.0004 0.790125 35.5145C0.304226 35.0286 0.03125 34.3696 0.03125 33.6825V2.59096C0.03125 1.90379 0.304226 1.24477 0.790125 0.758875C1.27602 0.272976 1.93504 0 2.62221 0H5.21317C5.90033 0 6.55935 0.272976 7.04525 0.758875C7.53115 1.24477 7.80412 1.90379 7.80412 2.59096V33.6825ZM25.9408 33.6825C25.9408 34.3696 25.6679 35.0286 25.182 35.5145C24.6961 36.0004 24.037 36.2734 23.3499 36.2734H20.7589C20.0718 36.2734 19.4127 36.0004 18.9268 35.5145C18.4409 35.0286 18.168 34.3696 18.168 33.6825V2.59096C18.168 1.90379 18.4409 1.24477 18.9268 0.758875C19.4127 0.272976 20.0718 0 20.7589 0H23.3499C24.037 0 24.6961 0.272976 25.182 0.758875C25.6679 1.24477 25.9408 1.90379 25.9408 2.59096V33.6825Z" fill={color} />
        </svg>
    )
}

export default PauseIcon