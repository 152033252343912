import React from 'react'

const GateCloseIcon = ({ color, height, width }) => {
    return (
        <svg width={width ?? "35"} height={height ?? "25"} viewBox="0 0 35 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.7273 0V8.33333H9.54545V1.66667H6.36364V8.33333H3.18182V5H0V25H3.18182V21.6667H6.36364V25H9.54545V21.6667H12.7273V25H15.9091V21.6667H19.0909V25H22.2727V21.6667H25.4545V25H28.6364V21.6667H31.8182V25H35V5H31.8182V8.33333H28.6364V1.66667H25.4545V8.33333H22.2727V0H19.0909V8.33333H15.9091V0H12.7273ZM3.18182 11.6667H6.36364V18.3333H3.18182V11.6667ZM9.54545 11.6667H12.7273V18.3333H9.54545V11.6667ZM15.9091 11.6667H19.0909V18.3333H15.9091V11.6667ZM22.2727 11.6667H25.4545V18.3333H22.2727V11.6667ZM28.6364 11.6667H31.8182V18.3333H28.6364V11.6667Z" fill={color ?? "black"} />
        </svg>
    )
}

export default GateCloseIcon