import React, { useEffect, useRef, useState } from 'react';
import { UserService } from '../../../services/UserService';
import { User } from '../../../storage/store';
import { Width } from 'devextreme-react/cjs/chart';

const MotorSettings2 = ({applianceId, handleMotarConfigM2}) => {
  const [m1MotorConfig, setM1MotorConfig] = useState({});
  const [m2MotorConfig, setM2MotorConfig] = useState({});
  const [motorData, setMotorData] = useState({});
  const [openSelect, setOpenSelect] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);

  const motorTypeRef = useRef();
  const motorTypeSubRef = useRef();

  const hpRef = useRef();

  const startCapacitorOnTimeRef = useRef();
  const startCapacitorOnTimeSubRef = useRef();

  const maxRunTimeRef = useRef();
  const maxRunTimeSubRef = useRef();

  const highVoltageTripRef = useRef();
  const highVoltageTripSubRef = useRef();

  const lowVoltageTripRef = useRef();
  const lowVoltageTripSubRef = useRef();

  const overLoadAmpsRef = useRef();
  const overLoadAmpsSubRef = useRef();

  const overLoadOffTimeRef = useRef();
  const overLoadOffTimeSubRef = useRef();

  const dryRunAmpsRef = useRef();
  const dryRunAmpsSubRef = useRef();

  const workingHoursRef = useRef();
  const workingHoursSubRef = useRef();

  const onDelayTimeRef = useRef();
  const onDelayTimeSubRef = useRef();

  const cyclicOnTimeRef = useRef();
  const cyclicOnTimeSubRef = useRef();

  const cyclicOffTimeRef = useRef();
  const cyclicOffTimeSubRef = useRef();

  const dryRunOffTimeRef = useRef();
  const dryRunOffTimeSubRef = useRef();

  useEffect(() => {
    (async () => {
      const propertyId = User.getPropertyId();
      const resp = await UserService.getConfig(propertyId);
      console.log(resp);
      const motor = [...resp.devices]
        .map((item) => item.motorsConfig)
        .filter((item) => item.length > 0)
        .map((item) => {
          const collection = item.filter((element) => element?.applianceId === applianceId);
          return collection;
        });
      setMotorData(...motor[0]);
      console.log(motor)
    })();
  }, []);
  console.log(applianceId)
  console.log(motorData);

  return (
    <>
      {motorData.settings ?
        <div>
          {(
            <div className='motor-setting-input-container'>
              <p>Motor Type</p>
              <div>
                <input
                  ref={motorTypeRef}
                  type='number'
                  className='motor-setting-input'
                  max={1}
                  min={0}
                  defaultValue={motorData.settings.motortype}
                  onChange={(e) => {
                    if (['0', '1'].includes(e.target.value)) {
                      motorData.settings.motorType = Number(e.target.value);
                      setMotorData({...motorData});
                      motorTypeRef.current.style.borderColor = 'black';
                      motorTypeSubRef.current.style.color = 'black';
                      setDisableUpdate(false);
                    } else {
                      motorTypeRef.current.style.borderColor = 'red';
                      motorTypeSubRef.current.style.color = 'red';
                      setDisableUpdate(true);
                    }
                  }}
                />
              </div>
              <sub ref={motorTypeSubRef}>Select appropriate Motor Type 0-Submersible & 1-Compressor</sub>
            </div>
          )}
          <div className='motor-setting-input-container'>
            <p>Start Capacitor ON time</p>
            <div>
              <input
                ref={startCapacitorOnTimeRef}
                type='number'
                className='motor-setting-input'
                defaultValue={motorData.settings.startCapacitorOnTime}
                onChange={(e) => {
                  if (e.target.value!='' && e.target.value >= 1 && e.target.value <= 9) {
                    motorData.settings.startCapacitorOnTime = Number(e.target.value);
                    setMotorData({...motorData})
                    handleMotarConfigM2({startCapacitorOnTime:e.target.value});
                    startCapacitorOnTimeRef.current.style.borderColor = 'black'
                    startCapacitorOnTimeSubRef.current.style.color = 'black'
                    setDisableUpdate(false);
                  } else {
                    startCapacitorOnTimeRef.current.style.borderColor = 'red'
                    startCapacitorOnTimeSubRef.current.style.color = 'red'
                    setDisableUpdate(true);
                  }
                }}
              />
            </div>
            <sub ref={startCapacitorOnTimeSubRef}>Range from 1 to 9 seconds</sub>
          </div>

          <div className='motor-setting-input-container'>
            <p>Horse Power</p>
            <div style={{ position: 'relative' }}>
              <div onClick={() => setOpenSelect(true)}>
                <div ref={hpRef} type='text' className='motor-setting-input' max={9} min={1}>{motorData.settings.horsePower / 100}</div>
              </div>
              {openSelect && <div className='motor-setting-select'>
                <div onClick={(e) => {
                  hpRef.current.value =
                    motorData.settings.horsePower = 50;
                  setMotorData({ ...motorData });
                  setOpenSelect(false);
                }}>0.5</div>
                <div onClick={(e) => {
                  motorData.settings.horsePower = 100;
                  setMotorData({ ...motorData });
                  setOpenSelect(false);
                }}>1.0</div><div onClick={(e) => {
                  motorData.settings.horsePower = 150;
                  setMotorData({ ...motorData });
                  setOpenSelect(false);
                }}>1.5</div><div onClick={(e) => {
                  motorData.settings.horsePower = 200;
                  setMotorData({ ...motorData });
                  setOpenSelect(false);
                }}>2.0</div><div onClick={(e) => {
                  motorData.settings.horsePower = 250;
                  setMotorData({ ...motorData });
                  setOpenSelect(false);
                }}>2.5</div>
              </div>}
            </div>
            <sub>Select appropriate motor HP</sub>
          </div>

          <div className='motor-setting-input-container'>
            <p>Max Run Time</p>
            <div>
              <input 
                ref={maxRunTimeRef}
                type='number' 
                className='motor-setting-input' 
                max={999} 
                min={0} 
                defaultValue={motorData.settings.maxRunTime}
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 0 && e.target.value <= 999) {
                    motorData.settings.maxRunTime = Number(e.target.value);
                    setMotorData({...motorData})
                    maxRunTimeRef.current.style.borderColor = 'black';
                    maxRunTimeSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    maxRunTimeRef.current.style.borderColor = 'red';
                    maxRunTimeSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
                
            </div>
            <sub ref={maxRunTimeSubRef}>Range from 0 to 999 seconds</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>High Voltage Trip</p>
            <div>
              <input 
                ref={highVoltageTripRef}
                type='number' 
                className='motor-setting-input' 
                max={280} 
                min={220} 
                defaultValue={motorData.settings.highVoltageTrip}
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 220 && e.target.value <= 280) {
                    motorData.settings.highVoltageTrip = e.target.value;
                    setMotorData({...motorData});
                    highVoltageTripRef.current.style.borderColor = 'black';
                    highVoltageTripSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    highVoltageTripRef.current.style.borderColor = 'red';
                    highVoltageTripSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={highVoltageTripSubRef}>Range from 220 to 280 volts</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Low Voltage Trip</p>
            <div>
              <input 
                ref={lowVoltageTripRef}
                type='number' 
                className='motor-setting-input' 
                max={210} 
                min={170} 
                defaultValue={motorData.settings.lowVoltageTrip} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 170 && e.target.value <= 210) {
                    motorData.settings.lowVoltageTrip = e.target.value;
                    setMotorData({...motorData});
                    lowVoltageTripRef.current.style.borderColor = 'black';
                    lowVoltageTripSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    lowVoltageTripRef.current.style.borderColor = 'red';
                    lowVoltageTripSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={lowVoltageTripSubRef}>Range from 170 to 210 volts</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Over Load Amps</p>
            <div>
              <input 
              ref={overLoadAmpsRef}
                className='motor-setting-input' 
                type='number' 
                max={30} 
                min={1} 
                defaultValue={motorData.settings.overLoadAmps/10} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 1 && e.target.value <= 30) {
                    motorData.settings.overLoadAmps = e.target.value*10;
                    setMotorData({...motorData});
                    overLoadAmpsRef.current.style.borderColor = 'black';
                    overLoadAmpsSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    overLoadAmpsRef.current.style.borderColor = 'red';
                    overLoadAmpsSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={overLoadAmpsSubRef}>Range from 1 to 30 Amps</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Over load Off Time</p>
            <div>
              <input 
              ref={overLoadOffTimeRef}
                type='number' 
                className='motor-setting-input' 
                max={60} 
                min={1} 
                defaultValue={motorData.settings.overLoadOffTime} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 1 && e.target.value <= 60) {
                    motorData.settings.overLoadOffTime = e.target.value;
                    setMotorData({...motorData});
                    overLoadOffTimeRef.current.style.borderColor = 'black';
                    overLoadOffTimeRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    overLoadOffTimeRef.current.style.borderColor = 'red';
                    overLoadOffTimeSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={overLoadOffTimeSubRef}>Range from 1 to 60 seconds</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Dry Run Amps</p>
            <div>
              <input 
              ref={dryRunAmpsRef}
                className='motor-setting-input' 
                type='number' 
                max={30} 
                min={1} 
                defaultValue={motorData.settings.dryRunAmps} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 1 && e.target.value <= 30) {
                    motorData.settings.dryRunAmps = e.target.value;
                    setMotorData({...motorData});
                    dryRunAmpsRef.current.style.borderColor = 'black';
                    dryRunAmpsSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    dryRunAmpsRef.current.style.borderColor = 'red';
                    dryRunAmpsSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={dryRunAmpsSubRef}>Range from 1 to 30 Amps</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Dry Run Off Time</p>
            <div>
              <input 
                ref={dryRunOffTimeRef}
                type='number' 
                className='motor-setting-input' 
                max={999} 
                min={0} 
                defaultValue={motorData.settings.dryRunOffTime} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 0 && e.target.value <= 999) {
                    motorData.settings.dryRunAmps = e.target.value;
                    setMotorData({...motorData});
                    dryRunOffTimeRef.current.style.borderColor = 'black';
                    dryRunOffTimeSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    dryRunOffTimeRef.current.style.borderColor = 'red';
                    dryRunOffTimeSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={dryRunOffTimeSubRef}>Range from 0 to 999 seconds</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Working Hours</p>
            <div>
              <input 
              ref={workingHoursRef}
                type='number' 
                className='motor-setting-input' 
                min={0} 
                max={2} 
                defaultValue={motorData.settings.workingHours} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 0 && e.target.value <= 2) {
                    motorData.settings.workingHours = e.target.value;
                    setMotorData({...motorData});
                    workingHoursRef.current.style.borderColor = 'black';
                    workingHoursSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    workingHoursRef.current.style.borderColor = 'red';
                    workingHoursSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={workingHoursSubRef}>0-Day & Night, 1-Day Only and 2-Night Only</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Ondelay Time</p>
            <div>
              <input 
                ref={onDelayTimeRef}
                type='number'  
                className='motor-setting-input' 
                max={999} 
                min={0} 
                defaultValue={motorData.settings.onDelayTime} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 0 && e.target.value <= 999) {
                    motorData.settings.onDelayTime = e.target.value;
                    setMotorData({...motorData});
                    onDelayTimeRef.current.style.borderColor = 'black';
                    onDelayTimeSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    onDelayTimeRef.current.style.borderColor = 'red';
                    onDelayTimeSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={onDelayTimeSubRef}>Range from 0 to 999 seconds</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Cyclic on Time</p>
            <div>
              <input 
                ref={cyclicOnTimeRef}
                type='number' 
                className='motor-setting-input' 
                max={999} 
                min={1} 
                defaultValue={motorData.settings.cyclicOnTime} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 1 && e.target.value <= 999) {
                    motorData.settings.cyclicOnTime = e.target.value;
                    setMotorData({...motorData});
                    cyclicOnTimeRef.current.style.borderColor = 'black';
                    cyclicOnTimeSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    cyclicOnTimeRef.current.style.borderColor = 'red';
                    cyclicOnTimeSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={cyclicOnTimeSubRef}>Range from 1 to 999 seconds</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Cyclic off Time</p>
            <div>
              <input 
                ref={cyclicOffTimeRef}
                type='number' 
                className='motor-setting-input' 
                max={999} 
                min={0} 
                defaultValue={motorData.settings.cyclicOffTime} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 0 && e.target.value <= 999) {
                    motorData.settings.cyclicOffTime = e.target.value;
                    setMotorData({...motorData});
                    cyclicOffTimeRef.current.style.borderColor = 'black';
                    cyclicOffTimeSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    cyclicOffTimeRef.current.style.borderColor = 'red';
                    cyclicOffTimeSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={cyclicOffTimeSubRef}>Range from 0 to 999 seconds</sub>
          </div>

          <div style={{textAlign:'center'}}>
          <button
                style={
                    disableUpdate
                      ?
                      {width:'100%', backgroundColor:'rgb(181, 179, 179)', padding:'5px', borderRadius:'15px', border:'none', color:'grey', cursor:'no-drop'}
                      :
                        {width:'100%', backgroundColor:'skyblue', padding:'5px', borderRadius:'15px', border:'none', cursor:'pointer'}
                      }
                onClick={(e)=>{
                  e.preventDefault();
                  if(disableUpdate==false){
                    handleMotarConfigM2({...motorData.settings});
                  }else{
                    window.alert('cannot update')
                  }

                }}
              >Update</button>
          </div>
        </div> : <div>No data</div>}
    </>
  );
};

export default MotorSettings2;
