import React from 'react'

const GateButtonOpenLightIcon = () => {
    return (
        <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_ddiiii_411_97)">
                <circle cx="40.5" cy="40.5" r="37.5" fill="#F3F3F3" />
                <circle cx="40.5" cy="40.5" r="37" stroke="#9B9898" />
            </g>
            <circle cx="40.5002" cy="40.4997" r="31.3356" fill="#F3F3F3" />
            <path d="M32.5455 52V29H29.3636V35.5714H26.1818V32.2857H23V52H26.1818V48.7143H29.3636V52H32.5455ZM26.1818 45.4286V38.8571H29.3636V45.4286H26.1818ZM54.8182 32.2857V35.5714H51.6364V29H48.4545V52H51.6364V48.7143H54.8182V52H58V32.2857H54.8182ZM54.8182 45.4286H51.6364V38.8571H54.8182V45.4286Z" fill="black" />
            <defs>
                <filter id="filter0_ddiiii_411_97" x="-2" y="-2" width="85" height="85" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-1" dy="-1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_411_97" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="1" dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_411_97" result="effect2_dropShadow_411_97" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_411_97" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="5" dy="5" />
                    <feGaussianBlur stdDeviation="6.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0.9 0" />
                    <feBlend mode="normal" in2="shape" result="effect3_innerShadow_411_97" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-5" dy="-5" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.9 0" />
                    <feBlend mode="normal" in2="effect3_innerShadow_411_97" result="effect4_innerShadow_411_97" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="5" dy="-5" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect4_innerShadow_411_97" result="effect5_innerShadow_411_97" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-5" dy="5" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect5_innerShadow_411_97" result="effect6_innerShadow_411_97" />
                </filter>
            </defs>
        </svg>
    )
}

export default GateButtonOpenLightIcon