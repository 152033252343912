import React, { useEffect, useState } from 'react'
import { useUser } from '../../../Context/context'
import { UserService } from '../../../services/UserService';
import { User } from '../../../storage/store';
import ScheduleCard from './ScheduleCard';
import CircleBarSpinner from '../../../components/common/CircleBarSpinner';
import DotSpinner from '../../../components/common/DotSpinner';
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import ThemeColor from '../../../assets/theme/ThemeColor';
import { Center } from 'devextreme-react/cjs/map';

const Schedule = ({ setDrawer }) => {
  const [schedules, setSchedules] = useState();
  const [isDelete, setIsDelete] = useState(false);
  const { device, scheduleG, setScheduleG } = useUser();
  useEffect(() => {
    getSchedules();
  }, [scheduleG])
  async function getSchedules() {
    try {
      const propertyId = User.getPropertyId();
      const response = await UserService.getRoutineByApplianceId(propertyId, device.applianceId);
      setSchedules(response);
    } catch (e) {
      setSchedules(null)
    }
  }
  // const colors = ['#fa8f8f','#8fedfa', '#8c8d8e', '#99f6b2', '#df99f6', '#f6e799', '#f599f6']
  return (
    <div style={{ padding: '10px 20px', marginBottom: '150px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 0px 20px 0px' }}>

        {/* <div style={{position:'relative'}}> */}
        <div className='schedule-add' style={{ ...AddDelete, backgroundColor: 'green' }}>
          <IoMdAdd style={{ fontSize: '20px' }} />
          <div onClick={() => {
            setScheduleG({ applianceId: device?.applianceId, deviceId: device.deviceId, deviceType: device.deviceType, state: 'add' });
            setDrawer('open');
          }}>Add</div>
        </div>
        {/* </div> */}

        <div style={{position:'absolute',right:0, bottom:0, width:'100%', display:'flex', justifyContent:'Center', alignItems:'center', zIndex:3, background:'white', padding:'20px 0px'}}>
          {!isDelete ? <div className='schedule-delete' onClick={() => setIsDelete(true)} style={{ ...AddDelete, backgroundColor: '#6c1309' }}>
            <MdDelete style={{ fontSize: '20px' }} />
            <div>Delete</div>
          </div>
            :
            <div onClick={() => setIsDelete(false)} style={{ ...AddDelete, backgroundColor: '#6c1309' }}>
              <MdCancel style={{ fontSize: '20px' }} />
              <div>Cancel</div>
            </div>
          }
        </div>

      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {schedules ?
          (schedules.length > 0 ? (schedules.map((item, index) => <ScheduleCard getSchedules={getSchedules} isDelete={isDelete} key={`scheduleCard${index}`} setDrawer={setDrawer} data={item} color={ThemeColor.quickActions[index]} />)) : <div> No Schedules found</div>) : <div style={{ display: 'flex', alignItems: 'center', height: '80lvh' }}><DotSpinner /></div>
        }
      </div>
    </div>
  )
}

const AddDelete = { display: 'flex', padding: '5px 7px', borderRadius: '5px', alignItems: 'center', color: 'white', gap: '5px', cursor: 'pointer' }

export default Schedule