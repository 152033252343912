import React from 'react'

const LeftRunningArrow = () => {
    return (
        <>
            <i className="arrow left element3"></i>
            <i className="arrow left element2"></i>
            <i className="arrow left element1"></i>
        </>
    )
}

export default LeftRunningArrow