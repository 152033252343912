import React from 'react'

const ShutterGateOpenDarkIcon = () => {
    return (
        <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_ddiiii_417_316)">
                <circle cx="40.5" cy="40.5" r="37.5" fill="#B2AA95" />
                <circle cx="40.5" cy="40.5" r="37" stroke="#A09576" />
            </g>
            <circle cx="40.5" cy="40.5" r="30.5" fill="#B2AA95" />
            <path d="M23 25H58V32.7778H54.1111V56.1111H50.2222V32.7778H30.7778V56.1111H26.8889V32.7778H23V25ZM32.7222 34.7222H48.2778V38.6111H32.7222V34.7222Z" fill="#584C2B" />
            <defs>
                <filter id="filter0_ddiiii_417_316" x="-1" y="-1" width="83" height="83" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-1" dy="-1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.431373 0 0 0 0 0.411765 0 0 0 0 0.360784 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_417_316" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="1" dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.964706 0 0 0 0 0.921569 0 0 0 0 0.807843 0 0 0 0.3 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_417_316" result="effect2_dropShadow_417_316" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_417_316" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" dy="4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.431373 0 0 0 0 0.411765 0 0 0 0 0.360784 0 0 0 0.9 0" />
                    <feBlend mode="normal" in2="shape" result="effect3_innerShadow_417_316" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-4" dy="-4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.964706 0 0 0 0 0.921569 0 0 0 0 0.807843 0 0 0 0.9 0" />
                    <feBlend mode="normal" in2="effect3_innerShadow_417_316" result="effect4_innerShadow_417_316" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" dy="-4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.431373 0 0 0 0 0.411765 0 0 0 0 0.360784 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect4_innerShadow_417_316" result="effect5_innerShadow_417_316" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-4" dy="4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.431373 0 0 0 0 0.411765 0 0 0 0 0.360784 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect5_innerShadow_417_316" result="effect6_innerShadow_417_316" />
                </filter>
            </defs>
        </svg>
    )
}

export default ShutterGateOpenDarkIcon