import React, { useEffect, useState } from 'react';
import { FaChevronCircleDown } from "react-icons/fa";
import { useUser } from '../../../Context/context'
import ThemeColor from '../../../assets/theme/ThemeColor';
import moment, { weekdays } from 'moment';
import { off } from 'devextreme/events';
import { UserService } from '../../../services/UserService';
import { User } from '../../../storage/store';
import { useToggleTop } from '../../../components/common/ToggleTop';
import CircleBarSpinner from '../../../components/common/CircleBarSpinner';

export function convertUTCToIST(timeString) {

    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const utcDate = new Date(Date.UTC(1970, 0, 1, hours, minutes, seconds));

    // IST offset from UTC in minutes
    const IST_OFFSET = 5 * 60 + 30;

    // Add the IST offset to the UTC time
    utcDate.setMinutes(utcDate.getMinutes() + IST_OFFSET);

    // Format the IST time as hh:mm:ss
    const istHours = utcDate.getUTCHours().toString().padStart(2, '0');
    const istMinutes = utcDate.getUTCMinutes().toString().padStart(2, '0');
    const istSeconds = utcDate.getUTCSeconds().toString().padStart(2, '0');
    console.log(`${istHours}:${istMinutes}:${istSeconds}`)
    return `${istHours}:${istMinutes}:${istSeconds}`;
}
export function convertISTToUTC(timeString) {
    // Parse the input time string as an IST date
    const [hours, minutes] = timeString.split(':').map(Number);
    const seconds = '00'
    const istDate = new Date(Date.UTC(1970, 0, 1, hours, minutes, seconds));

    // IST offset from UTC in minutes (negative since we are subtracting it)
    const IST_OFFSET = -(5 * 60 + 30);

    // Subtract the IST offset from the IST time
    istDate.setMinutes(istDate.getMinutes() + IST_OFFSET);

    // Format the UTC time as hh:mm:ss
    const utcHours = istDate.getUTCHours().toString().padStart(2, '0');
    const utcMinutes = istDate.getUTCMinutes().toString().padStart(2, '0');
    const utcSeconds = istDate.getUTCSeconds().toString().padStart(2, '0');
    console.log(`${utcHours}:${utcMinutes}:00`);
    return `${utcHours}:${utcMinutes}:00`;
}
const AddSchedule = ({ setDrawer, }) => {
    const { device, scheduleG, setScheduleG } = useUser();
    const [showAction, setShowAction] = useState(false);
    const setToggleTop = useToggleTop();
    const actions = {
        "WATER_LEVEL_CONTROLLER": {
            id: 1 || 2,
            trait: 701,
            value: 1 || 0,
        },
    }
    const [data, setData] = useState({
        name: '',
        on: [],
        off: [],
        action: {
            id: 1,
            trait: null,
            value: null,
            action:null,
            rl:null
        },
        time: '',
        weekDays: []
    })

    const _date = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    useEffect(() => {
        if (scheduleG.data) {
            data.name = scheduleG.data.name;
            data.on = scheduleG.data.control.on;
            data.off = scheduleG.data.control.off;
            let time = convertUTCToIST(scheduleG.data.time);
            data.time = time;
            data.weekDays = scheduleG.data.weekDays;
            if (scheduleG.data.control.action) {
                if(device.meta.model==="GC_1"){
                    data.action.action = scheduleG.data.control.action.action;
                    data.action.rl = scheduleG.data.control.action.rl;
                }else{
                    data.action.id = scheduleG.data.control.action.id;
                    data.action.trait = scheduleG.data.control.action.trait;
                    data.action.value = scheduleG.data.control.action.value;
                }
            } else {
                data.action.id = 1;
                data.action.trait = null;
                data.action.value = null;
            }
            setData({ ...data });
        }
    }, []);
    const handleWeekDays = (val) => {
        if (data.weekDays.includes(val)) {
            const index = data.weekDays.indexOf(val);
            data.weekDays.splice(index, 1);
        } else {
            data.weekDays.push(val);
        }
        setData({ ...data });
    }

    const addSchedule = async () => {
        if (data.name == null || data.name == "")
            setToggleTop('Add Routine Name.');
        else if (data.time == null || data.time == "")
            setToggleTop('Set Time');
        else if (data.on.length < 1 && data.off.length < 1) {
            setToggleTop('Mention device status')
        }
        else if (data.weekDays.length <= 0)
            setToggleTop('Select Week Days')
        else {
            let payload;
            let time = convertISTToUTC(data.time)
            console.log(time);
            if (device.deviceType === "WATER_LEVEL_CONTROLLER") {
                if (data.action.value == null) {
                    setToggleTop(' Set Motor Status',);
                    return 0;
                } else {
                    payload = {
                        control: {
                            off: data.off,
                            on: data.on,
                            action: {
                                id: data.action.id,
                                trait: 701,
                                value: data.action.value
                            }
                        },
                        name: data.name,
                        time: time,
                        weekDays: data.weekDays
                    };
                }
            }
            else if (device.deviceType === "CURTAIN_MODULE") {

                payload = {
                    control: {
                        off: data.off,
                        on: data.on,
                        action: {
                            id: data.action.id,
                            trait: 901,
                            value: 1
                        }
                    },
                    name: data.name,
                    time: time,
                    weekDays: data.weekDays
                };
            }
            else if (device.meta.model === "GC_1") {

                payload = {
                    control: {
                        off: data.off,
                        on: data.on,
                        action: {
                            rl: Number(data.action.rl),
                            action: Number(data.action.action),
                        }
                    },
                    name: data.name,
                    time: time,
                    weekDays: data.weekDays
                };
            } else {
                payload = {
                    control: {
                        off: data.off,
                        on: data.on,
                    },
                    name: data.name,
                    time: time,
                    weekDays: data.weekDays
                };
            }

            try {
                const propertyId = User.getPropertyId();
                const result = await UserService.addRoutine(propertyId, payload);
                // setVisible(false);
                setToggleTop("Routine added", 'green');
                console.log(payload);
                setScheduleG(null);
                setDrawer('close');

            } catch (e) {
                console.log('failed addRoutine');
                setToggleTop("Routine not added", 'red')
            }
        }

    }

    {/* JSX */ }
    return (
        <>
            {scheduleG
                ?
                <div style={{ padding: '30px 30px 40px 30px', display: 'flex', flexDirection: 'column', gap: '50px', overflow:'hidden' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap:'wrap' }}>
                        <div className='add-schedule-control' onClick={() => setDrawer('close')}>Cancel</div>
                        <div className='add-schedule-heading'>{data.name !== "" ? "Update Schedule" : "Add Schedule"}</div>
                        <div className='add-schedule-control' onClick={addSchedule}>save</div>
                    </div>
                    <div>
                        <div>Schedule Name</div>
                        <div>
                            <input
                                type='text'
                                style={data.name != ""
                                    ?
                                    {
                                        border: 'none',
                                        borderBottom: '1px solid black',
                                        width: '100%',
                                        outline: 'none'
                                    }
                                    :
                                    {
                                        border: 'none',
                                        borderBottom: '1px solid black',
                                        width: '100%',
                                        outline: 'none',
                                        color: 'grey'
                                    }
                                }
                                onChange={(e) => {
                                    data.name = e.target.value;
                                    setData({ ...data });
                                }}
                                defaultValue={data.name}
                                readOnly={scheduleG?.data ? true : false}
                            />
                        </div>
                        <div style={{ color: ThemeColor.pirPrimary }}>{data.name != "" ? <sub>Name can't be edited</sub> : <sub>Name can't be edited once created</sub>}</div>
                    </div>
                    <div>
                        <div>Start Time</div>
                        <div>
                            <input type='time' style={{ border: 'none', outline: 'none' }} onChange={(e) => {
                                // let d = moment(e.target.value, 'hh:mm a')
                                //     // .utc()
                                //     .format('HH:mm:ss');
                                data.time = e.target.value;
                                setData({ ...data });
                            }}
                                defaultValue={data.time}
                            />
                            {/* <inpu/> */}
                        </div>
                    </div>

                    <div>
                        <div>Select days to repeat</div>
                        <div className='add-schedule-weekdays'>
                            {_date.map((item, index) => <div key={`weekdays${index}`} style={data?.weekDays.includes(index.toString()) ? { backgroundColor: ThemeColor.pirPrimary, color: 'white' } : {}}>
                                <div style={{ cursor: 'pointer' }} onClick={() => handleWeekDays(index.toString())}>{item}</div>
                            </div>
                            )}
                        </div>
                    </div>

                    {/* Motor selector */}

                    {/* {device?.deviceType === "WATER_LEVEL_CONTROLLER" && } */}

                    {/* switches WLC */}
                    <div style={{ height: '100px' }}>
                        {device.deviceType === "WATER_LEVEL_CONTROLLER"
                            ?
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>

                                    <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                        <div
                                            style={data.action.id === 1 ? { flex: 1, cursor: 'pointer', backgroundColor: ThemeColor.pirPrimary, color: 'white' } : { flex: 1, cursor: 'pointer' }}
                                            onClick={() => {
                                                data.action.id = 1;
                                                setData({ ...data });
                                            }}
                                        >
                                            Motor 1
                                        </div>
                                        <div
                                            style={data.action.id === 2 ? { flex: 1, cursor: 'pointer', backgroundColor: ThemeColor.pirPrimary, color: 'white' } : { flex: 1, cursor: 'pointer' }}
                                            onClick={() => {
                                                data.action.id = 2;
                                                setData({ ...data });
                                            }}
                                        >
                                            Motor 2
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        <div>Action: </div>
                                        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            {data.action.value == 1 ?
                                                <div style={{ padding: '5px 10px', backgroundColor: 'green', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                    ON<FaChevronCircleDown style={{ cursor: 'pointer' }} onClick={() => setShowAction(!showAction)} />
                                                </div>
                                                :
                                                data.action.value == 0 ?
                                                    <div style={{ padding: '5px 10px', backgroundColor: 'red', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                        OFF<FaChevronCircleDown style={{ cursor: 'pointer' }} onClick={() => setShowAction(!showAction)} />
                                                    </div>
                                                    :
                                                    <div style={{ padding: '5px 10px', backgroundColor: 'grey', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                        DEFAULT<FaChevronCircleDown style={{ cursor: 'pointer' }} onClick={() => setShowAction(!showAction)} />
                                                    </div>
                                            }
                                            <></>
                                            {showAction &&
                                                <div style={{ padding: '10px 20px 10px 10px', boxShadow: 'rgba(0, 0, 0, 0.133) 0px 0px 5px 1px', display: 'flex', flexDirection: 'column', }}>
                                                    <div style={{ cursor: 'pointer' }}>DEFAULT</div>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                                        data.on = [scheduleG.applianceId];
                                                        data.off = [];
                                                        data.action.value = 1;
                                                        data.action.trait = 701;
                                                        setData({ ...data })
                                                        setShowAction(false);
                                                        // setShowAction()
                                                    }}>ON</div>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                                        data.on = [scheduleG.applianceId];
                                                        data.off = [];
                                                        data.action.value = 0;
                                                        data.action.trait = 701;
                                                        setData({ ...data });
                                                        setShowAction(false);
                                                    }}>OFF</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            device.deviceType === "CURTAIN_MODULE"
                                ?
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                        <div
                                            style={(data.action.id === 1 || data.action.id === 2) ? { flex: 1, cursor: 'pointer', backgroundColor: ThemeColor.pirPrimary, color: 'white' } : { flex: 1, cursor: 'pointer' }}
                                            onClick={() => {
                                                data.action.id = 1;
                                                setData({ ...data });
                                            }}
                                        >
                                            Motor 1
                                        </div>
                                        <div
                                            style={(data.action.id === 3 || data.action.id === 4) ? { flex: 1, cursor: 'pointer', backgroundColor: ThemeColor.pirPrimary, color: 'white' } : { flex: 1, cursor: 'pointer' }}
                                            onClick={() => {
                                                data.action.id = 3;
                                                setData({ ...data });
                                            }}
                                        >
                                            Motor 2
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        <div>Action: </div>
                                        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            {((data.action.id == 1 || data.action.id == 3) && data.action.value == 1) ?
                                                <div style={{ padding: '5px 10px', backgroundColor: 'green', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                    OPEN<FaChevronCircleDown onClick={() => setShowAction(!showAction)} />
                                                </div>
                                                :
                                                ((data.action.id == 2 || data.action.id == 4) && data.action.value == 1) ?
                                                    <div style={{ padding: '5px 10px', backgroundColor: 'red', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                        CLOSE<FaChevronCircleDown onClick={() => setShowAction(!showAction)} />
                                                    </div>
                                                    :
                                                    data.action.value == 0 ?
                                                        <div style={{ padding: '5px 10px', backgroundColor: 'black', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                            STOP<FaChevronCircleDown onClick={() => setShowAction(!showAction)} />
                                                        </div>
                                                        :
                                                        <div style={{ padding: '5px 10px', backgroundColor: 'grey', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                            DEFAULT<FaChevronCircleDown onClick={() => setShowAction(!showAction)} />
                                                        </div>

                                            }
                                            <></>
                                            {showAction &&
                                                <div style={{ padding: '10px 20px 10px 10px', boxShadow: 'rgba(0, 0, 0, 0.133) 0px 0px 5px 1px', display: 'flex', flexDirection: 'column', }}>
                                                    <div style={{ cursor: 'pointer' }}>DEFAULT</div>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                                        data.on = [scheduleG.applianceId];
                                                        data.off = [];
                                                        if (data.action.id == 1 || data.action.id == 2) {
                                                            data.action.id = 1;
                                                            data.action.value = 1;
                                                            data.action.trait = 901;
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                        } else if (data.action.id == 3 || data.action.id == 4) {
                                                            data.action.id = 3;
                                                            data.action.value = 1;
                                                            data.action.trait = 901;
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                        }
                                                        // setShowAction()
                                                    }}>OPEN</div>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                                        data.on = [scheduleG.applianceId];
                                                        data.off = [];
                                                        if (data.action.id == 1 || data.action.id == 2) {
                                                            data.action.id = 2;
                                                            data.action.value = 1;
                                                            data.action.trait = 901;
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                        } else if (data.action.id == 3 || data.action.id == 4) {
                                                            data.action.id = 4;
                                                            data.action.value = 1;
                                                            data.action.trait = 901;
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                        }
                                                    }}>CLOSE</div>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                                        data.on = [scheduleG.applianceId];
                                                        data.off = [];
                                                        if (data.action.id == 1 || data.action.id == 2) {
                                                            data.action.id = 1;
                                                            data.action.value = 0;
                                                            data.action.trait = 901;
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                        } else if (data.action.id == 3 || data.action.id == 4) {
                                                            data.action.id = 3;
                                                            data.action.value = 0;
                                                            data.action.trait = 901;
                                                            setData({ ...data });
                                                            setShowAction(false);
                                                        }
                                                    }}>STOP</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                :
                               ( device.meta.model === "GC_1")
                                    ?
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        <div>Action: </div>
                                        <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            {(data.action.action == 0) ?
                                                <div style={{ padding: '5px 10px', backgroundColor: 'green', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                    OPEN<FaChevronCircleDown onClick={() => setShowAction(!showAction)} />
                                                </div>
                                                :
                                                (data.action.action == 1) ?
                                                    <div style={{ padding: '5px 10px', backgroundColor: 'red', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                        CLOSE<FaChevronCircleDown onClick={() => setShowAction(!showAction)} />
                                                    </div>
                                                    :
                                                    (data.action.action == 2) ?
                                                        <div style={{ padding: '5px 10px', backgroundColor: 'black', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                            STOP<FaChevronCircleDown onClick={() => setShowAction(!showAction)} />
                                                        </div>
                                                        :
                                                        <div style={{ padding: '5px 10px', backgroundColor: 'grey', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                            DEFAULT<FaChevronCircleDown onClick={() => setShowAction(!showAction)} />
                                                        </div>

                                            }
                                            <></>
                                            {showAction &&
                                                <div style={{ padding: '10px 20px 10px 10px', boxShadow: 'rgba(0, 0, 0, 0.133) 0px 0px 5px 1px', display: 'flex', flexDirection: 'column', }}>
                                                    <div style={{ cursor: 'pointer' }}>DEFAULT</div>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                                        data.on = [scheduleG.applianceId];
                                                        data.off = [];
                                                        if (device.deviceType==="SWING_GATE") {
                                                            data.action.action = 0;
                                                            data.action.rl = device.pin
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                        } else {
                                                            data.action.action = 0;
                                                            data.action.rl = 1
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                        }
                                                        // setShowAction()
                                                    }}>OPEN</div>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                                        data.on = [scheduleG.applianceId];
                                                        data.off = [];
                                                        if (device.deviceType==="SWING_GATE") {
                                                            data.action.action = 1;
                                                            data.action.rl = device.pin
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                        } else {
                                                            data.action.action = 1;
                                                            data.action.rl = 2;
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                        }
                                                    }}>CLOSE</div>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => {
                                                        data.on = [scheduleG.applianceId];
                                                        data.off = [];
                                                        if (device.deviceType==="SWING_GATE") {
                                                            data.action.action = 2;
                                                            data.action.rl = device.pin
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                        } else {
                                                            data.action.action = 2;
                                                            data.action.rl = 3
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                        }
                                                    }}>STOP</div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    device.deviceType.includes('SENSOR')
                                        ?
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <div>Action: </div>
                                            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                {data.on.includes(scheduleG.applianceId) ?
                                                    <div style={{ padding: '5px 10px', backgroundColor: 'green', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                        Arm<FaChevronCircleDown onClick={() => setShowAction(!showAction)} />
                                                    </div>
                                                    :
                                                    data.off.includes(scheduleG.applianceId) ?
                                                        <div style={{ padding: '5px 10px', backgroundColor: 'red', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                            Unarm<FaChevronCircleDown onClick={() => setShowAction(!showAction)} />
                                                        </div>
                                                        :
                                                        <div style={{ padding: '5px 10px', backgroundColor: 'grey', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                            DEFAULT<FaChevronCircleDown onClick={() => setShowAction(!showAction)} />
                                                        </div>
                                                }
                                                <></>
                                                {showAction &&
                                                    <div style={{ padding: '10px 20px 10px 10px', boxShadow: 'rgba(0, 0, 0, 0.133) 0px 0px 5px 1px', display: 'flex', flexDirection: 'column', }}>
                                                        <div style={{ cursor: 'pointer' }}>
                                                            DEFAULT
                                                        </div>
                                                        <div style={{ cursor: 'pointer' }} onClick={() => {
                                                            data.on = [scheduleG.applianceId];
                                                            data.off = []
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                            // setShowAction()
                                                        }}>Arm</div>
                                                        <div style={{ cursor: 'pointer' }} onClick={() => {
                                                            data.off = [scheduleG.applianceId]
                                                            data.on = []
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                        }}>Unarm</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            <div>Action: </div>
                                            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                {data.on.includes(scheduleG.applianceId) ?
                                                    <div style={{ padding: '5px 10px', backgroundColor: 'green', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                        ON<FaChevronCircleDown onClick={() => setShowAction(!showAction)} />
                                                    </div>
                                                    :
                                                    data.off.includes(scheduleG.applianceId) ?
                                                        <div style={{ padding: '5px 10px', backgroundColor: 'red', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                            OFF<FaChevronCircleDown onClick={() => setShowAction(!showAction)} />
                                                        </div>
                                                        :
                                                        <div style={{ padding: '5px 10px', backgroundColor: 'grey', borderRadius: '4px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                            DEFAULT<FaChevronCircleDown onClick={() => setShowAction(!showAction)} />
                                                        </div>
                                                }
                                                <></>
                                                {showAction &&
                                                    <div style={{ padding: '10px 20px 10px 10px', boxShadow: 'rgba(0, 0, 0, 0.133) 0px 0px 5px 1px', display: 'flex', flexDirection: 'column', }}>
                                                        <div style={{ cursor: 'pointer' }}>
                                                            DEFAULT
                                                        </div>
                                                        <div style={{ cursor: 'pointer' }} onClick={() => {
                                                            data.on = [scheduleG.applianceId];
                                                            data.off = []
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                            // setShowAction()
                                                        }}>ON</div>
                                                        <div style={{ cursor: 'pointer' }} onClick={() => {
                                                            data.off = [scheduleG.applianceId]
                                                            data.on = []
                                                            setData({ ...data })
                                                            setShowAction(false);
                                                        }}>OFF</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                        }
                    </div>
                </div>
                :
                <CircleBarSpinner />
            }
        </>

    )
}

export default AddSchedule