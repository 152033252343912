import ThemeColor from "../../assets/theme/ThemeColor";

function Footer() {

  return (
    <footer style={styles.footer}>
      {/* <div style={styles.footerContent}>
        <div style={styles.section}>
          <img src={hashh_connect}/>
        </div>
        <div style={styles.section}>
          <h3>General</h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
          </ul>
        </div>
        <div style={styles.section}>
          <h3>Contact Us</h3>
          <p>Email: info@example.com</p>
          <p>Phone: 123-456-7890</p>
        </div>
      </div> */}
      <div style={styles.footerBottom}>
        <p style={{fontSize:'16px',}}>&copy;All Rights Reserved | <b>HASHh Automations.</b></p>
      </div>
    </footer>
    
  );
}
const styles = {
  footer: {
    backgroundColor: ThemeColor.bgColor,
    color: '#fff',
    padding: '10px 0',
  },
  footerBottom: {
    marginTop: '20px',
    textAlign: 'center',
    color:ThemeColor.hashhPrimary,
    
  }
};
export default Footer;
