import { Position } from 'devextreme-react/cjs/autocomplete';
import React, { useEffect, useState } from 'react'
import { IoCloseOutline } from "react-icons/io5";
import Hr from './Hr';

const SideDrawer = ({ children, drawer, setDrawer, background, title }) => {
  // const [drawer, setDrawer] = useState(false)

  // setTimeout(()=>setDrawer(true), 2000)
  if (drawer === 'close') setTimeout(() => setDrawer(''), 800);
  return (
    <>
      {drawer !== '' && (
        <div className={(drawer === 'open' || drawer === '') ?
          `sideDrawer-overlay sideDrawer-overlay-animation-open` :
          (drawer === 'close' || drawer === '') ?
            `sideDrawer-overlay sideDrawer-overlay-animation-close` :
            `sideDrawer-overlay`
        } onClick={() => setDrawer('close')}>
        
         {/* drawer content */}
          <div
            style={{ backgroundColor: (background ?? "#FFFFFFF") }}
            className={`sideDrawer-main ${drawer === 'open' ? 'sd-open' : drawer === 'close' ? 'sd-close' : null}`}
            onClick={(e) => e.stopPropagation()}
          >
           <div style={{
            display:'flex',
            alignItems:'center',
            
            // border:'1px solid red',
           }}>
            <div className='sd-close-icon'style={{
            // border:'1px solid yellow'
              }} >
                <IoCloseOutline onClick={() => setDrawer('close')} style={{ fontSize: '30px', color: 'grey', cursor: 'pointer' }} />
              </div>
              <p style={{
            height:'100%', marginTop:'15px', fontSize:'20px'
              }}>{title}</p>
           </div>
            
            {/* <div className='sd-hrline'/> */}
            <Hr color={'grey'} size={'0.5px'} margin={'5px 0'}/>
            <main className="sideDrawer-content">
              {children}
            </main>
          </div>
        </div>
      )}
    </>
  )
}

export default SideDrawer
