import React from "react";
import { Row, Typography, Drawer, } from "antd";
import { useLocation } from "react-router-dom";
import "react-datetime-picker/dist/DateTimePicker.css";

const SwitchInfo = ({ isOpen, isClose }) => {
    const params = useLocation();
    const { Text } = Typography;

    const onChildrenDrawerClose = () => {
        isClose();
    };

    return (
        <>
            <Drawer
                title="Information"
                className="settings-drawer"
                mask={true}
                width={570}
                onClose={onChildrenDrawerClose}
                placement={"right"}
                visible={isOpen}
                footer={
                    <Text style={{ color: "gray" }}>
                        <center>
                            Manufacturer <br /> HASHh Automations connected <br />{" "}
                            through HasHh Cloud
                        </center>
                    </Text>
                }
                footerStyle={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    background: "white",
                }}
            >
                <Row gutter={18}>
                    <Text
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginLeft: "10px",
                            marginRight: "10px",
                            paddingBottom: "10px",
                        }}
                    >
                        Device ID : {params.state.data.deviceId}
                    </Text>
                    <br />
                </Row>
                <Row gutter={18}>
                    <Text
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginLeft: "10px",
                            marginRight: "10px",
                            paddingBottom: "10px",
                        }}
                    >
                        Model : {params.state.data.meta.model}
                    </Text>
                    <br />
                </Row>
                <Row gutter={18}>
                    <Text
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginLeft: "10px",
                            marginRight: "10px",
                            paddingBottom: "10px",
                        }}
                    >
                        Build :
                    </Text>
                    <br />
                </Row>
                <Row gutter={18}>
                    <Text
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginLeft: "10px",
                            marginRight: "10px",
                            paddingBottom: "10px",
                        }}
                    >
                        Firemware Version :
                    </Text>
                    <br />
                </Row>
                <Row gutter={18}>
                    <Text
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginLeft: "10px",
                            marginRight: "10px",
                            paddingBottom: "10px",
                        }}
                    >
                        Vendor ID : {params.state.data.meta.vendorId}
                    </Text>
                    <br />
                </Row>
                <Row gutter={18}>
                    <Text
                        style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            marginLeft: "10px",
                            marginRight: "10px",
                            paddingBottom: "10px",
                        }}
                    >
                        Signal Strength(dbm) :
                    </Text>
                    <br />
                </Row>
            </Drawer>
        </>
    );
};
export default SwitchInfo;
