import React, { useState, useEffect } from "react";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Label,
  Format,
  Legend,
  Export,
} from "devextreme-react/chart";
import { UserService } from "../../../services/UserService";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { Card } from "antd";

function DayChart() {
  const [data, setData] = useState([]);
  const params = useLocation();
  const device = params?.state?.data;

  const [changeDate, setChangeDate] = useState(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const formattedDate = `${year}-${month}`;
    return formattedDate;
  });
  const fetchData = async () => {
    try {
      const res = await UserService.getDoorStatusForMonth(
        device.applianceId,
        changeDate
      );

      const result = res.map((item) => ({
        date: moment(item.bucket).format("MM-DD"),
        hour: moment(item.bucket).utc().hours(),
        openCount: parseInt(item.open_count),
        closeCount: parseInt(item.close_count),
      }));

      setData(result);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [changeDate]);

  return (
    <Card className="responsive-chart">
      <input
        type="month"
        value={changeDate}
        onChange={(e) => setChangeDate(e.target.value)}
        style={{ marginTop: "20px", marginBottom: "20px" }}
      />

      <Chart id="chart" title="Month Analysis of Door Sensor" dataSource={data}>
        <CommonSeriesSettings
          argumentField="date"
          type="bar"
          id="bar"
          hoverMode="allArgumentPoints"
          selectionMode="allArgumentPoints"
        >
          <Label visible={true} fontSize={10}>
            <Format type="fixedPoint" precision={0} />
          </Label>
        </CommonSeriesSettings>
        <Series
          valueField="openCount"
          name="Open-Count"
          cornerRadius={2}
          barPadding={20}
        />
        <Series
          valueField="closeCount"
          name="Close-Count"
          cornerRadius={2}
          barPadding={20}
        />
        <Legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
        ></Legend>
        <Export enabled={true} />
      </Chart>
    </Card>
  );
}
export default DayChart;
