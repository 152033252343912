import { Row, Card, Col } from "antd";
import { UserService } from "../../services/UserService";
import { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { AiFillThunderbolt } from "react-icons/ai";
import { User } from "../../storage/store";
import Main from "../../components/layout/Main";
import { useUser } from "../../Context/context";
import { floor } from "lodash";
import { useToggleTop } from "../../components/common/ToggleTop";
// import ToggleTop from "../../components/common/ToggleTop";

function Property() {
  const { pathname } = useLocation();
  const navigate = useHistory();
  const [isHovering, setIsHovering] = useState(false);
  const [editOption, setEditOption] = useState(null);
  const [floorName, setFloorName] = useState('');
  // const [updateMsg, setUpdateMsg] = useState('none');

  const { getRooms, floor, setFloor } = useUser();

  const setToggleTop = useToggleTop();

  const header = {
    background: "#8C8F9C",
    color: "white",
    width: "270px",
    height: "150px",
    margin: "10px",
    borderRadius: "20px",
    boxSizing: "borderBox",
    boxShadow: isHovering ? "5px 3px 5px #c5c5c5" : "",
    border: `1.7px solid ${"#8C8F9C"}`,
    cursor: "pointer",
    position: "relative",
    transition: "1000ms",
    AnimationDuration: "3s",
    AnimationIterationCount: "1",
    AnimationDirection: "reverse",
    appearance: "auto",
    AnimationName: "bounce",
    transition: "box-shadow .20s ease-in-out",
    transition: "background-position 0.5s ease-in-out",
  };

  function goToRoom(floor, room) {
    console.log("change!!");
    let props = {
      floorName: floor,
      roomName: room.name,
      roomId: room._id
    };
    navigate.push("Room", { params: props });
  }

  useEffect(() => {
    getRooms();
    console.log(document.cookie);
  }, []);

  function updateFloor(data, floorId, newData) {
    data.floors = data.floors.map(floor => {
      if (floor._id == floorId) {
        return { ...floor, ...newData }
      }

      return floor;
    });
  }

  const handleFloorNameChange = async (floorData) => {
    if (floorName === "") {
      window.alert(`Floor name not changed`);
      setToggleTop("Floor name not changed", 'red');
      // setTimeout(()=>setUpdateMsg('none'), 3000)
      return null;
    }
    const propertyid = User.getPropertyId();
    var config = await UserService.getConfig(propertyid);
    console.log(floorData)
    if (config && floorName !== "" && window.confirm(`Do you want to update your floor name to "${floorName}"?`)!==false) {
      updateFloor(config, floorData.floorId, { name: floorName });
      try {
        const response = await UserService.updateConfig(
          config,
          propertyid,
        );
        if (response === "Updated successfully") {
          getRooms();
          setToggleTop(`Floor name updated successfully`,'green');
          // setTimeout(() => {
          //   setUpdateMsg('none');
          // }, 3000)

          return null;
        } else {
          getRooms();
         
          setToggleTop("Floor name not updated", 'red');
          // setTimeout(() => {
          //   setUpdateMsg('none');
          // }, 3000)
          return null;
        }


        // showAlert('success', 'Floor Name updated successfully!');
      } catch (error) {
        console.log(error);
      } finally {
        console.log("finally....");
      }
      console.log("After: " + JSON.stringify(config.floors));
    }else {
      getRooms();
      setToggleTop("Floor name not updated", 'red');
      // setTimeout(() => {
      //   setUpdateMsg('none');
      // }, 3000)
      return null;
    }

    //Update FloorName in UI
  }
  return (
    <>
      {/* {updateMsg === "green"
        ? <ToggleTop color='green' message="Floor name updated successfully" toggle={setUpdateMsg}/>
        : updateMsg === "red" ? <ToggleTop color='red' message="" toggle={setUpdateMsg}/>
          : null
      } */}
      <Main switchProperty={false}>
        {floor?.map((floor_item, index) => <div key={index + 300} style={{
          backgroundColor: 'white',
          padding: '30px',
          borderRadius:'10px',
          marginBottom:'30px'
        }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {editOption === index
                ?
                <input
                  style={{
                    outline: 'none',
                    padding: '5px',
                    borderRadius: '5px',
                    border: 'none',
                    transition: 'all 2s',
                    color: 'grey',
                    fontSize: '1.2em',
                    fontWeight: '500',
                    boxShadow: '0px 0px 5px grey'
                  }}
                  autoCorrect="off"
                  type="text"
                  defaultValue={floor_item.label}
                  autoFocus
                  onChange={(e) => setFloorName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.stopPropagation();
                      setEditOption(null);
                      handleFloorNameChange(floor_item);
                    }
                  }}
                />
                :
                <div style={{ fontSize: '1.2em', fontWeight: '500', padding: '5px 2px' }}>{floor_item.label}</div>}
            </div>
            {editOption === index
              ?

              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <button
                  type='button'
                  style={{ cursor: 'pointer', background:'transparent', border: 'none', color: 'rgba(0, 115, 174, 1)', maxHeight: '30px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setEditOption(null);
                    handleFloorNameChange(floor_item);
                  }}>update</button>
              </div>
              :
              <button type='button'
                style={{
                  cursor: 'pointer',
                  border: 'none',
                  borderRadius: '5px',
                  backgroundColor: 'transparent',
                  color: 'black',
                  padding: '2px 10px',
                  maxHeight: '30px'
                }}
                onClick={(e) => {
                  setFloorName("");
                  e.preventDefault();
                  e.stopPropagation();
                  setEditOption(index);
                }}>Edit</button>}
          </div>
          <hr />
          <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '40px', overflowY: 'hidden', overflowX: 'scroll', transition: 'all 2s', padding: '20px' }}>

            {floor_item?.rooms && floor_item.rooms.map((room, index) => (
              <div
                key={index + 200}
                className="zoom-on-hover"
                style={{
                  color: 'white',
                  backgroundColor: '#7E7A93',
                  borderRadius: '15px',
                  padding: '20px 20px',
                  width: '250px',
                  boxSizing: 'border-box',
                  height: '150px',
                  flexShrink: 0,
                  cursor: 'pointer',
                  transition: 'all 1s'
                }}
                onClick={() => goToRoom(floor_item.label, room)}
              >
                <p style={{ fontSize: '22px', height: '80px', overflow: 'scrollX' }}>
                  {room.name}
                </p>

                <div
                  style={{
                    fontSize: '10px',
                    display: 'flex',
                  }}
                >
                  <div>
                    <AiFillThunderbolt
                      style={{
                        fontSize: '15px',
                        color: '#ffc53d',
                      }}
                    />
                  </div>
                  <p style={{ color: '#ffff', fontSize: '0.9em' }}>300kw</p>
                </div>
              </div>
            ))}

          </div>
        </div>)}
      </Main>


      {/* <Main>
        <div className="tabled">
          <Row gutter={[16, 0]}>
            {floor
              ? floor.map((c, index) => (
                <Card
                  key={index + 100}
                  title={editOption === index ? <input style={{ outline: 'none', border: 'none', borderBottom: '1px solid black', transition: 'all 2s' }} type="text" defaultValue={c.label} autoFocus /> : c.label}
                  style={{ width: "100%", marginBottom: "12px", position: 'relative' }}
                >

                  {editOption === index ? <button type='button' style={{ cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setEditOption(null); console.log(e) }}>update</button> : <button type='button' style={{ cursor: 'pointer' }} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setEditOption(index);
                    console.log(e)
                  }}>Edit</button>}

                  <Row gutter={[16, 16]}>
                    {c.rooms
                      ? c.rooms.map((key, index) => (
                        <Col gutter={6} key={index + 1000}>
                          <div
                            style={header}
                            onClick={() => goToRoom(c.label, key.name)}
                          >
                            <div>
                              <div>
                                <p
                                  style={{
                                    float: "left",
                                    fontSize: "20px",
                                    paddingTop: "20px",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  {" "}
                                  {key.name}<br />{" "}
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      paddingTop: "50px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <AiFillThunderbolt
                                      style={{
                                        fontSize: "15px",
                                        color: "#ffc53d",
                                      }}
                                    />
                                    300kw
                                  </p>{" "}
                                </p>
                              </div>
                              <div>
                                <p
                                  style={{
                                    float: "right",
                                    fontSize: "14px",
                                    paddingTop: "27px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  {" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))
                      : null}
                  </Row>
                </Card>
              ))
              : null}
          </Row>
        </div>
      </Main> */}
    </>
  );
}

export default Property;
