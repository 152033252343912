import React from 'react'
import { useUser } from '../../Context/context';

export const useToggleTop=(message, color)=>{
    const {toggleTop, setToggleTop} = useUser();
    
    const setToggle =(msg, color)=>{
        toggleTop.color= color ?? "blue";
        toggleTop.message= msg ?? "toggle top";
        toggleTop.toggle=true;
        setToggleTop({...toggleTop});
    }
    return setToggle;
}

const ToggleTop = () => {

    const {toggleTop, setToggleTop} = useUser();
    const message = toggleTop.message ?? "Toggle top component.";
    const color = toggleTop.color ?? 'skyblue';
    setTimeout(() => {
        toggleTop.color="skyblue";
        toggleTop.message="toggle top";
        toggleTop.toggle=false;
        setToggleTop({...toggleTop});
      }, 2000)
    return (
            <div
                id='Msg'
                className='msg'
                style={{
                    position: 'fixed',
                    width: '100%', 
                    // backgroundImage: `linear-gradient(to right, transparent, ${color}, transparent)`,
                    background: color,
                    top: '0px', 
                    zIndex: '100', 
                    color: 'white', 
                    textAlign:'center',
                    padding:'10px 0px 10px 0px',
                    // top:'-40px'
                 }}
                >
                    {message}
            </div>
    )
}

export default ToggleTop