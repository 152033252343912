import React from 'react'

const CurtainCloseIcon = ({color, height, width}) => {
    return (
        <svg width={width ?? "39"} height={height ?? "37"} viewBox="0 0 39 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39 3.44963H0V0H39V3.44963ZM1.77273 36.2211H17.7273V5.17445H1.77273V36.2211ZM37.2273 5.17445H21.2727V36.2211H37.2273V5.17445Z" fill={color} />
        </svg>
    )
}

export default CurtainCloseIcon