import React from 'react'

const GateButtonPauseDarkIcon = () => {
  return (
    <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_ddiiii_417_276)">
                                <circle cx="33.5" cy="33.5" r="30.5" fill="#867A57" />
                            </g>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.916 43.3027C30.916 43.7373 30.7434 44.1541 30.4361 44.4615C30.1287 44.7688 29.7119 44.9414 29.2773 44.9414H27.6387C27.2041 44.9414 26.7873 44.7688 26.48 44.4615C26.1726 44.1541 26 43.7373 26 43.3027V23.6387C26 23.2041 26.1726 22.7873 26.48 22.48C26.7873 22.1726 27.2041 22 27.6387 22H29.2773C29.7119 22 30.1287 22.1726 30.4361 22.48C30.7434 22.7873 30.916 23.2041 30.916 23.6387V43.3027ZM42.3867 43.3027C42.3867 43.7373 42.2141 44.1541 41.9068 44.4615C41.5995 44.7688 41.1826 44.9414 40.748 44.9414H39.1094C38.6748 44.9414 38.258 44.7688 37.9507 44.4615C37.6433 44.1541 37.4707 43.7373 37.4707 43.3027V23.6387C37.4707 23.2041 37.6433 22.7873 37.9507 22.48C38.258 22.1726 38.6748 22 39.1094 22H40.748C41.1826 22 41.5995 22.1726 41.9068 22.48C42.2141 22.7873 42.3867 23.2041 42.3867 23.6387V43.3027Z" fill="white" />
                            <defs>
                                <filter id="filter0_ddiiii_417_276" x="0" y="0" width="67" height="67" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="1" dy="1" />
                                    <feGaussianBlur stdDeviation="1" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.411765 0 0 0 0 0.298039 0 0 0 0.5 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_417_276" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="-1" dy="-1" />
                                    <feGaussianBlur stdDeviation="1" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.456136 0 0 0 0 0.413003 0 0 0 0 0.299777 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="effect1_dropShadow_417_276" result="effect2_dropShadow_417_276" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_417_276" result="shape" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="-2" dy="-2" />
                                    <feGaussianBlur stdDeviation="2.5" />
                                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.411765 0 0 0 0 0.298039 0 0 0 0.9 0" />
                                    <feBlend mode="normal" in2="shape" result="effect3_innerShadow_417_276" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="2" dy="2" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.411765 0 0 0 0 0.298039 0 0 0 0.9 0" />
                                    <feBlend mode="normal" in2="effect3_innerShadow_417_276" result="effect4_innerShadow_417_276" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="-2" dy="2" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.411765 0 0 0 0 0.298039 0 0 0 0.2 0" />
                                    <feBlend mode="normal" in2="effect4_innerShadow_417_276" result="effect5_innerShadow_417_276" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="2" dy="-2" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0.454902 0 0 0 0 0.411765 0 0 0 0 0.298039 0 0 0 0.2 0" />
                                    <feBlend mode="normal" in2="effect5_innerShadow_417_276" result="effect6_innerShadow_417_276" />
                                </filter>
                            </defs>
                        </svg>
  )
}

export default GateButtonPauseDarkIcon