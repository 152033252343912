import React from 'react'
import Main from '../../components/layout/Main'
import Lottie from 'react-lottie';
import update_lottie from '../../assets/json/update_lottie.json'

const RuleEngine = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: update_lottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Main>
      <div style={styles.container}>
        <Lottie options={defaultOptions} height={350} width={350} />
        <div style={styles.text}><b>COMING SOON...</b></div>
        <p style={{textAlign:'center'}}>We're putting in the effort to ensure your experience with our new and impressive site is exceptional.</p>
      </div>
    </Main>
  )
}

const styles = {
  container: {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '10px',
  },
  text: {
    fontSize: '20px',
    textAlign: 'center',
    margin: '20px',
    backgroundColor: '#fff'

  }
}
export default RuleEngine