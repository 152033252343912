import React, { useEffect, useRef, useState } from 'react';
import { UserService } from '../../../services/UserService';
import { User } from '../../../storage/store';

const MotorSettings1 = ({applianceId, handleMotarConfigM1}) => {
  const [motorData, setMotorData] = useState({});
  const [openSelect, setOpenSelect] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);

  const hpRef = useRef();

  const maxRunTimeRef = useRef();
  const maxRunTimeSubRef = useRef();

  const highVoltageTripRef = useRef();
  const highVoltageTripSubRef = useRef();

  const lowVoltageTripRef = useRef();
  const lowVoltageTripSubRef = useRef();

  const overLoadAmpsRef = useRef();
  const overLoadAmpsSubRef = useRef();

  const overLoadOffTimeRef = useRef();
  const overLoadOffTimeSubRef = useRef();

  const dryRunAmpsRef = useRef();
  const dryRunAmpsSubRef = useRef();

  const workingHoursRef = useRef();
  const workingHoursSubRef = useRef();

  const onDelayTimeRef = useRef();
  const onDelayTimeSubRef = useRef();

  const stlOffDelayRef = useRef();
  const stlOffDelaySubRef = useRef();

  const stlOnDelayRef = useRef();
  const stlOnDelaySubRef = useRef();

  const dryRunOffTimeRef = useRef();
  const dryRunOffTimeSubRef = useRef();

  useEffect(() => {
    (async () => {
      const propertyId = User.getPropertyId();
      const resp = await UserService.getConfig(propertyId);
      console.log(resp);
      const motor = [...resp.devices]
        .map((item) => item.motorsConfig)
        .filter((item) => item.length > 0)
        .map((item) => {
          const collection = item.filter((element) => element?.applianceId === applianceId);
          return collection;
        });
      setMotorData(...motor[0]);
      console.log(motor)
    })();
  }, []);

  console.log(motorData);

  return (
    <>
    <div>
      <div style={{fontSize:'20px', borderBottom:'1px solid rgb(220, 218, 218)', padding:'10px'}}>Water Level Controller Settings</div>
      {motorData.settings ?
        <div style={{display:'flex', flexDirection:'column'}}>
          <div className='motor-setting-input-container'>
            <p>Horse Power</p>
            <div style={{ position: 'relative', cursor:'pointer' }}>
              <div onClick={() => setOpenSelect(!openSelect)}>
                <div ref={hpRef} type='text' className='motor-setting-input' max={9} min={1}>{motorData.settings.horsePower / 100}</div>
              </div>
              {openSelect && <div className='motor-setting-select'>
                <div
                  className='motor-setting-option'
                 onClick={(e) => {
                  hpRef.current.value =
                    motorData.settings.horsePower = 50;
                  setMotorData({ ...motorData });
                  setOpenSelect(!openSelect);
                }}>0.5</div>

                <div className='motor-setting-option' onClick={(e) => {
                  motorData.settings.horsePower = 100;
                  setMotorData({ ...motorData });
                  setOpenSelect(!openSelect);
                }}>1.0</div>

                <div className='motor-setting-option' onClick={(e) => {
                  motorData.settings.horsePower = 150;
                  setMotorData({ ...motorData });
                  setOpenSelect(!openSelect);
                }}>1.5</div>
                
                <div className='motor-setting-option' onClick={(e) => {
                  motorData.settings.horsePower = 200;
                  setMotorData({ ...motorData });
                  setOpenSelect(!openSelect);
                }}>2.0</div>
                
                <div className='motor-setting-option' onClick={(e) => {
                  motorData.settings.horsePower = 250;
                  setMotorData({ ...motorData });
                  setOpenSelect(!openSelect);
                }}>2.5</div>
              </div>}
            </div>
            <sub>Select appropriate motor HP</sub>
          </div>

          <div className='motor-setting-input-container'>
            <p>Max Run Time</p>
            <div>
              <input 
                ref={maxRunTimeRef}
                type='number' 
                className='motor-setting-input' 
                max={999} 
                min={0} 
                defaultValue={motorData.settings.maxRunTime}
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 0 && e.target.value <= 999) {
                    motorData.settings.maxRunTime = Number(e.target.value);
                    setMotorData({...motorData})
                    maxRunTimeRef.current.style.borderColor = 'black';
                    maxRunTimeSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    maxRunTimeRef.current.style.borderColor = 'red';
                    maxRunTimeSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
                
            </div>
            <sub ref={maxRunTimeSubRef}>Range from 0 to 999 seconds</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>High Voltage Trip</p>
            <div>
              <input 
                ref={highVoltageTripRef}
                type='number' 
                className='motor-setting-input' 
                max={280} 
                min={220} 
                defaultValue={motorData.settings.highVoltageTrip}
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 220 && e.target.value <= 280) {
                    motorData.settings.highVoltageTrip = e.target.value;
                    setMotorData({...motorData});
                    highVoltageTripRef.current.style.borderColor = 'black';
                    highVoltageTripSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    highVoltageTripRef.current.style.borderColor = 'red';
                    highVoltageTripSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={highVoltageTripSubRef}>Range from 220 to 280 volts</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Low Voltage Trip</p>
            <div>
              <input 
                ref={lowVoltageTripRef}
                type='number' 
                className='motor-setting-input' 
                max={210} 
                min={170} 
                defaultValue={motorData.settings.lowVoltageTrip} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 170 && e.target.value <= 210) {
                    motorData.settings.lowVoltageTrip = e.target.value;
                    setMotorData({...motorData});
                    lowVoltageTripRef.current.style.borderColor = 'black';
                    lowVoltageTripSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    lowVoltageTripRef.current.style.borderColor = 'red';
                    lowVoltageTripSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={lowVoltageTripSubRef}>Range from 170 to 210 volts</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Over Load Amps</p>
            <div>
              <input 
              ref={overLoadAmpsRef}
                className='motor-setting-input' 
                type='number' 
                max={30} 
                min={1} 
                defaultValue={motorData.settings.overLoadAmps/10} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 1 && e.target.value <= 30) {
                    motorData.settings.overLoadAmps = e.target.value*10;
                    setMotorData({...motorData});
                    overLoadAmpsRef.current.style.borderColor = 'black';
                    overLoadAmpsSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    overLoadAmpsRef.current.style.borderColor = 'red';
                    overLoadAmpsSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={overLoadAmpsSubRef}>Range from 1 to 30 Amps</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Over load Off Time</p>
            <div>
              <input 
              ref={overLoadOffTimeRef}
                type='number' 
                className='motor-setting-input' 
                max={60} 
                min={1} 
                defaultValue={motorData.settings.overLoadOffTime} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 1 && e.target.value <= 60) {
                    motorData.settings.overLoadOffTime = e.target.value;
                    setMotorData({...motorData});
                    overLoadOffTimeRef.current.style.borderColor = 'black';
                    overLoadOffTimeRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    overLoadOffTimeRef.current.style.borderColor = 'red';
                    overLoadOffTimeSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={overLoadOffTimeSubRef}>Range from 1 to 60 seconds</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Dry Run Amps</p>
            <div>
              <input 
              ref={dryRunAmpsRef}
                className='motor-setting-input' 
                type='number' 
                max={30} 
                min={1} 
                defaultValue={motorData.settings.dryRunAmps} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 1 && e.target.value <= 30) {
                    motorData.settings.dryRunAmps = e.target.value;
                    setMotorData({...motorData});
                    dryRunAmpsRef.current.style.borderColor = 'black';
                    dryRunAmpsSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    dryRunAmpsRef.current.style.borderColor = 'red';
                    dryRunAmpsSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={dryRunAmpsSubRef}>Range from 1 to 30 Amps</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Dry Run Off Time</p>
            <div>
              <input 
                ref={dryRunOffTimeRef}
                type='number' 
                className='motor-setting-input' 
                max={999} 
                min={0} 
                defaultValue={motorData.settings.dryRunOffTime} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 0 && e.target.value <= 999) {
                    motorData.settings.dryRunAmps = e.target.value;
                    setMotorData({...motorData});
                    dryRunOffTimeRef.current.style.borderColor = 'black';
                    dryRunOffTimeSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    dryRunOffTimeRef.current.style.borderColor = 'red';
                    dryRunOffTimeSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={dryRunOffTimeSubRef}>Range from 0 to 999 seconds</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Working Hours</p>
            <div>
              <input 
              ref={workingHoursRef}
                type='number' 
                className='motor-setting-input' 
                min={0} 
                max={2} 
                defaultValue={motorData.settings.workingHours} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 0 && e.target.value <= 2) {
                    motorData.settings.workingHours = e.target.value;
                    setMotorData({...motorData});
                    workingHoursRef.current.style.borderColor = 'black';
                    workingHoursSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    workingHoursRef.current.style.borderColor = 'red';
                    workingHoursSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={workingHoursSubRef}>0-Day & Night, 1-Day Only and 2-Night Only</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Ondelay Time</p>
            <div>
              <input 
                ref={onDelayTimeRef}
                type='number'  
                className='motor-setting-input' 
                max={999} 
                min={0} 
                defaultValue={motorData.settings.onDelayTime} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 0 && e.target.value <= 999) {
                    motorData.settings.onDelayTime = e.target.value;
                    setMotorData({...motorData});
                    onDelayTimeRef.current.style.borderColor = 'black';
                    onDelayTimeSubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    onDelayTimeRef.current.style.borderColor = 'red';
                    onDelayTimeSubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={onDelayTimeSubRef}>Range from 0 to 999 seconds</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Source Tank Low Level On Delay</p>
            <div>
              <input 
                ref={stlOnDelayRef}
                type='number' 
                className='motor-setting-input' 
                max={999} 
                min={1} 
                defaultValue={motorData.settings.stlOnDelay} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 1 && e.target.value <= 999) {
                    motorData.settings.stlOnDelay = e.target.value;
                    setMotorData({...motorData});
                    stlOnDelayRef.current.style.borderColor = 'black';
                    stlOnDelaySubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    stlOnDelayRef.current.style.borderColor = 'red';
                    stlOnDelaySubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={stlOnDelaySubRef}>Range from 1 to 999 seconds</sub>
          </div>


          <div className='motor-setting-input-container'>
            <p>Source Tank Low Level Off Delay</p>
            <div>
              <input 
                ref={stlOffDelayRef}
                type='number' 
                className='motor-setting-input' 
                max={999} 
                min={0} 
                defaultValue={motorData.settings.stlOffDelay} 
                onChange={(e) => {
                  console.log(Number(e.target.value));
                  if (e.target.value!='' && e.target.value >= 0 && e.target.value <= 999) {
                    motorData.settings.stlOffDelay = e.target.value;
                    setMotorData({...motorData});
                    stlOffDelayRef.current.style.borderColor = 'black';
                    stlOffDelaySubRef.current.style.color = 'black';
                    setDisableUpdate(false);
                  } else {
                    stlOffDelayRef.current.style.borderColor = 'red';
                    stlOffDelaySubRef.current.style.color = 'red';
                    setDisableUpdate(true);
                  }
                }} 
              />
            </div>
            <sub ref={stlOffDelaySubRef}>Range from 0 to 999 seconds</sub>
          </div>
          <div style={{textAlign:'center'}}>
              <button
                style={
                    disableUpdate
                      ?
                      {width:'100%', backgroundColor:'rgb(181, 179, 179)', padding:'5px', borderRadius:'15px', border:'none', color:'grey', cursor:'no-drop'}
                      :
                        {width:'100%', backgroundColor:'skyblue', padding:'5px', borderRadius:'15px', border:'none', cursor:'pointer'}
                      }
                onClick={(e)=>{
                  e.preventDefault();
                  if(disableUpdate==false){
                    handleMotarConfigM1({...motorData.settings})
                  }else{
                    window.alert('cannot update')
                  }

                }}
              >Update</button>
          </div>
          
          
        </div> : <div>No data</div>}
    </div>
      
    </>
  );
};

export default MotorSettings1;
