import React from 'react';
import Hashh from "../assets/images/Hashh.png";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Width } from 'devextreme-react/cjs/chart';


const Welcome = () => {
  return (
    <div >
      <div className='welcome-main'> 
        <div className='welcome-logo-parent'><img src={Hashh} alt="logo" className="image-fluid" /></div>
        <div>
          <div className='welcome-greet'>Welocome to</div>
          <div className='welcome-heading'>HASHh Automations </div>
          <div className='welcome-login-parent'><Link to='/Login' style={{ color: 'white'}}><p className='welcome-login'>Login <ArrowRightOutlined style={{color:'white',textAlign:'right'}} /></p></Link></div>
        </div>
        
      </div>
      <footer className='welcome-footer'>
        <p className="welcome-copyright">
          Copyright © 2023 by <a href="#pablo">HASHh Automations</a>.{" "}
        </p>
      </footer>
    </div>
  )
}

export default Welcome


// import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import { Layout, Button, Row, Col, Typography, Form } from "antd";
// import { ArrowRightOutlined } from "@ant-design/icons";
// const { Title } = Typography;
// const { Footer, Content } = Layout;

// const Welcome =()=> {
  
//     //Welcome Page Animation
//     let late = () => {
//       return (
//         <Col
//           xs={{ span: 24 }}
//           lg={{ span: 12 }}
//           md={{ span: 12 }}
//           className="invivible"
//         >
//           <center>
//             <Title
//               className="font-regular text-muted"
//               level={5}
//               style={{
//                 margin: "10px",
//                 marginTop: "200px",
//                 marginBottom: "10px",
//                 color: "black",
//                 fontSize: "18px",
//                 marginLeft: "20px",
//               }}
//             >
//               Welcome To{" "}
//               <p
//                 className="four"
//                 style={{
//                   fontSize: "36px",
//                   fontWeight: "600",
//                   marginLeft: "10px",
//                 }}
//               >
//                 HASHh Automations
//               </p>
//             </Title>

//             <Form.Item>
//               <Button
//                 type=""
//                 htmlType="submit"
//                 style={{
//                   width: "50%",
//                   padding: "10 10",
//                   fontSize: "18px",
//                   height: "6vh",
//                   backgroundColor: "#007bff",
//                   borderRadius: "10px",
//                 }}
//               >
//                 <Link to="./Login">
//                   <b
//                     style={{
//                       fontWeight: "400",
//                       marginLeft: "13px",
//                       color: "white",
//                     }}
//                   >
//                     {" "}
//                     Login 
//                   </b>
//                   <ArrowRightOutlined style={{color:'white',marginLeft:100,textAlign:'right'}} />
//                 </Link>
//               </Button>
//             </Form.Item>
//           </center>
//         </Col>
//       );
//     };

//     setTimeout(() => {
//       let elt = document.getElementsByClassName("invivible");
//       elt[0].classList.remove("invivible");
//     }, 1200);

//     return (
//       <>
//         <Layout className="layout-container">
//           <Content className="content">
//             <Row gutter={[24, 0]} justify="space-around">
//               <Col
//                 className="sign-img"
//                 style={{ padding: 12 }}
//                 xs={{ span: 24 }}
//                 lg={{ span: 12 }}
//                 md={{ span: 12 }}
//               >
//                 <center>
//                 </center>
//               </Col>
//               {late()}
//             </Row>
//           </Content>
          
//         </Layout>
//       </>
//     );
//   }

// export default Welcome
