import React, { useEffect, useRef, useState } from 'react'

const WCMsettings = (props) => {
    const [openCaliber, setOpenCaliber] = useState(false);
    const [device, setDevice] = useState([]);
    const [focusedDevice, setFocusedDevice] = useState(0);

    useEffect(() => {
        setDevice([...props.device]);
        setFocusedDevice(0);

    }, [])

    const travelTimeRef = useRef();

    const handleSubmit = () => {
        const value = travelTimeRef.current.value;
        console.log(value);
        device[focusedDevice].settings.maxDurationMs = String(value * 1000);
        setDevice([...device]);
    }
    console.log(focusedDevice);
    console.log(device);
    return (
        <>
            <header style={{ borderBottom: '1px solid grey', }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px',
                    marginTop: '30px',
                    padding: '5px 10px',
                }}>
                    <p style={{ fontSize: '20px' }}>
                        settings
                    </p>
                    <div style={{ fontSize: '1.1em', color: '#723ED8' }}>save</div>
                </div>

            </header>
            <div className='wcm-settings-l1'>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    {device.map((item, index) => <div
                        key={'wcmsetting' + index}
                        style={index === focusedDevice ? { backgroundColor: '#4B5234' } : { backgroundColor: 'white', color: 'black' }}
                        className='wcm-settings-device-switch'
                        onClick={() => { setFocusedDevice(Number(index)); setOpenCaliber(false) }}
                    >
                        device {index + 1}
                    </div>)}
                </div>

                <div
                    onClick={() => {
                        setOpenCaliber(!openCaliber);
                    }}
                    className='wcm-settings-calibration'>
                    <div >Quick Calibration</div>
                    <div>
                        <b>
                            10s&nbsp;&nbsp;<i style={{
                                transform: 'rotate(45deg)',
                                border: 'solid black',
                                borderWidth: '0px 3px 3px 0px',
                                display: 'inline-block',
                                padding: '3px',
                                marginBottom: '2px'
                            }}>
                            </i>
                        </b>
                    </div>
                </div>

                <div style={openCaliber ? { visibility: 'visible' } : { visibility: 'hidden' }} className='wcm-settings-edit-content'>
                    <div style={{ fontSize: '0.9em' }}>
                        1. Please Enter the trave time&nbsp;&nbsp;
                        <div style={{ width: '50px', display: 'inline-block' }}>{openCaliber && <input ref={travelTimeRef} type='text' defaultValue={String((device[focusedDevice].settings?.maxDurationMs) / 1000)} />}</div>&nbsp;&nbsp; Seconds
                    </div>
                    <p style={{ fontSize: '0.9em' }}>
                        2. Click <b>Submit</b> to complete the Calibration
                    </p>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-around'
                    }}>
                        <div
                            className='wcm-settings-edit-content-cancel'
                            onClick={() => setOpenCaliber(false)}
                        >
                            Cancel
                        </div>
                        <div
                            className='wcm-settings-edit-content-submit'
                            onClick={handleSubmit}
                        >
                            Submit
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WCMsettings