import React, { useEffect, useRef, useState } from 'react'
// import WLCTimeLineView from '../TimeLineViewTwoDevice'
import { AiFillInfoCircle, AiFillSetting } from 'react-icons/ai'
import { RiHeart3Fill } from 'react-icons/ri'
import ToggleSwitch from '../../common/ToggleSwitch'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import SideDrawer from '../../common/SideDrawer'
import { UserService } from '../../../services/UserService'
import { User } from '../../../storage/store'
import Tank from './Tank'
import MotorSettings2 from './MotorSettings2'
import MotorSettings1 from './MotorSettings1'
import ToggleTop from '../../common/ToggleTop'
import DotSpinner from '../../common/DotSpinner'
import CircleBarSpinner from '../../common/CircleBarSpinner'
import TimeLineViewTwoDevice from '../TimeLineViewTwoDevice'
import Info from '../Info'
import GateButtonCloseDarkIcon from '../../icons/GateButtonCloseDarkIcon'
import GateCloseIcon from '../../icons/GateCloseIcon'
import GateOpenIcon from '../../icons/GateOpenIcon'
import CurtainDoubleIcon from '../../icons/CurtainDoubleIcon'
import ShutterGateCloseDarkIcon from '../../icons/ShutterGateCloseDarkIcon'
import ShutterGateCloseLightIcon from '../../icons/ShutterGateCloseLightIcon'
import ShutterGateOpenDarkIcon from '../../icons/ShutterGateOpenDarkIcon'
import ShutterGateOpenLightIcon from '../../icons/ShutterGateOpenLightIcon'
import DetailFrame from '../../DetailFrame'
import { useUser } from '../../../Context/context'
import TimeLineView from '../TimeLineView'
import { truncate } from 'lodash'
import { trigger } from 'devextreme/events'

const WLC_1 = ({ ls, setActiveWLCMotor }) => {

    const params = useLocation();

    const [activeMotor, setActiveMotor] = useState();

    const { device, setDevice } = useUser();

    useEffect(() => {
        const keys = Object.keys(ls.devices);
        setActiveMotor(device.motorsConfig[0].applianceId);

        
    }, []);
    
    return (
        <>
                {
                    (device && Object.keys(device).length) ? (<>
                        <div className='wlc-motor-buttons'>
                            {device && (device.waterFlowType!=="TYPE_5" || device.waterFlowType!=="TYPE_4") && device.motorsConfig && device?.motorsConfig?.map((item, index) => <div  key={index + 'wlctlv'}>
                                <button
                                    style={item.applianceId==activeMotor ? style.activeMotorButton : style.passiveMotorButton}
                                    onClick={() => {
                                        setActiveMotor(item.applianceId); 
                                        device.props.state=ls.devices[item.applianceId]['state'];
                                        setDevice({...device});
                                        setActiveWLCMotor(index+1);
                                    }}
                                >
                                    Motor {index + 1}
                                </button>
                            </div>)}
                        </div>
                        <div className='wlc_tank_container' style={{ display: 'flex', justifyContent: 'space-evenly', padding: '30px' }}>
                            <div className='wlc_tank_container_l2' style={{ flex: '1', border: '1px' }}>
                                {device?.waterFlowType === "TYPE_3"
                                    ? <>
                                        <div className='wlc-grid-container'>
                                            <div className="wlc-grid-left"><Tank name="Over Head Tank 1" value={ls.overHeadTank1} /></div>
                                            <div className="wlc-grid-right"><Tank name="Over Head Tank 2" value={ls.overHeadTank2} /> </div>
                                            <div className="wlc-grid-center"><Tank name="Under Ground Tank" value={ls.underGroundTank} /></div>
                                        </div>
                                    </>
                                    : device?.waterFlowType === "TYPE_5"
                                        ? <><Tank name="Over Head Tank 1" value={ls?.overHeadTank} /></>
                                        : <>
                                            <div className='wlc_tank_container' style={{ display: "flex", justifyContent: 'space-around', alignItems: 'center', height: '50%' }}>
                                                <Tank name="Over Head Tank" value={ls?.overHeadTank} />
                                                <Tank name="Over Head Tank 2" value={ls?.underGroundTank} />
                                            </div>
                                        </>
                                }
                            </div>
                            <div className='wlc_timeline_view_container' >
                                {/* <TimeLineViewTwoDevice devices={params.state.data.motorsConfig} /> */}
                                <TimeLineView applianceId={activeMotor} />
                            </div>
                        </div>

                    </>)
                        : <div style={{
                            width: '100%',
                            height: '100lvh',
                            backgroundColor: 'white',
                            borderRadius: '18px',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                        }}>
                            <DotSpinner />
                        </div>
                }
        </>
    )
}
const style = {
    activeMotorButton: { backgroundColor: 'rgb(114, 62, 216)', padding: '5px 20px', borderRadius: '7px', border: 'none', cursor: 'pointer', color: 'white' },
    passiveMotorButton: { backgroundColor: '#bfbfbf', padding: '5px 20px', borderRadius: '7px', border: 'none', cursor: 'pointer', color: 'black' }
}
export default WLC_1