import React, { useEffect, useState } from 'react'
import DeviceChangeContainer from './DeviceChangeContainer'
import { Drawer, Space, Button } from 'antd';
import { set } from '@ant-design/plots/es/core/utils';
import { useUser } from '../../../Context/context';
import ThemeColor from '../../../assets/theme/ThemeColor';

const SearchDevicesChange = ({ changeEnable, setChangeEnable, data, setChange }) => {
    const [visible, setVisible] = useState(changeEnable);
    const { allDevices } = useUser();
    const [allDevicesState, setAllDevicesState] = useState([]);
    const [searchText, setSearchText] = useState('');

    const updateSearch = e => {
        const filteredData = allDevices.filter(item=>{
            return item.name.toLowerCase().includes(e.target.value.toLowerCase());
        })
        setAllDevicesState([...filteredData]);
    };
    useEffect(()=>{
        setAllDevicesState([...allDevices]);
    },[]);
    // const showDrawer = () => setVisible(true);
    const hideDrawer = () => {
        setVisible(false);
        setTimeout(() => setChangeEnable(false), 1000);
    };

    return (
        <>
            <Drawer
                className="routine-drawer"
                mask={true}
                width='80%'
                onClose={hideDrawer}
                open={visible}
                title="Add Change"
                extra={
                    <Space>
                        <div style={{cursor:'pointer'}} type="primary" onClick={hideDrawer}>
                            Save
                        </div>
                    </Space>
                }>
                <div>
                    <input
                        type="text"
                        placeholder="Search for devices..."
                        onChange={updateSearch}
                        // value={searchText}
                        style={{ backgroundColor: ThemeColor.lightgrey, border: 'none', padding: '15px', width: '100%', borderRadius: '5px' }}
                    />
                    <hr />
                </div>
                <div>
                    {allDevicesState && allDevicesState.length
                        ? allDevicesState.map((device, index) => {
                            return (
                                <DeviceChangeContainer
                                    key={index}
                                    sendChange={data => setChange(data)}
                                    device={device}
                                />
                            );
                        })
                        : null}
                </div>
            </Drawer>
        </>
    );
}

export default SearchDevicesChange