import { useState, useEffect } from "react";
import ThemeColor from '../../../assets/theme/ThemeColor';
import { Drawer, Button, Space } from "antd";
import { IoIosTime } from "react-icons/io";
import moment from "moment";
import SearchDevicesChange from "./SearchDevicesChange";
import { useUser } from "../../../Context/context";
import { UserService } from "../../../services/UserService";
import { User } from "../../../storage/store";
import { FcCancel } from "react-icons/fc";

const BottomSheet = ({ closeDrawer, routineSetter }) => {
    const [visible, setVisible] = useState(false);
    const [changeEnable, setChangeEnable] = useState(false);
    const [change, setChange] = useState([]);
    const [currentTime, setCurrentTime] = useState('');
    const [validate, setValidate] = useState(false);
    const propertyId = User.getPropertyId()
    const [time, setTime] = useState(false);
    const [validationErrors, setValidationErrors] = useState({
        name: false,
        time: false,
        weekDays: false,
        change: false
    });

    const { routineDevices, setRoutineDevices } = useUser();


    function getCurrentTime() {
        const now = new Date();
        let hours = now.getHours();
        let minutes = now.getMinutes();

        // Add leading zero if needed
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        routineDevices.time = `${hours}:${minutes}`;
        setRoutineDevices({ ...routineDevices });
        setCurrentTime(`${hours}:${minutes}`);
    }
    const showDrawer = () => setVisible(true);

    const hideDrawer = async () => {
        let resetRoutine = {
            on: [],
            off: [],
            name: '',
            time: '',
            weekDays: []
        }
        setRoutineDevices({...resetRoutine});
        setVisible(false);
        setTimeout(() => closeDrawer(), 1000);

    };
    const validateRoutine = (e) => {
        e.preventDefault();
        const errors = {};

        if (!routineDevices.name || !routineDevices.name.trim().length) {
            errors.name = true;
        }
        if (!routineDevices.time) {
            errors.time = true;
        }
        if (routineDevices.weekDays.length === 0) {
            errors.weekDays = true;
        }
        if ([...routineDevices.on, ...routineDevices.off] == 0) {
            errors.change = true;
        }

        setValidationErrors(errors);

        // If there are no errors, proceed with routine creation
        if (Object.values(errors).every(error => !error)) {
            addRoutine();
            setTimeout(() => routineSetter(), 1000)
        }
    }
    const addRoutine = async () => {
        const payload = {
            control: {
                off: [...routineDevices.off],
                on: [...routineDevices.on],
            },
            name: routineDevices.name,
            time:
                moment(routineDevices.time, 'hh:mm a')
                    .utc()
                    .format('HH:mm:ss'),
            weekDays: [...routineDevices.weekDays]
        };
        try {
            const result = await UserService.addRoutine(propertyId, payload);
            console.log(result);
            setVisible(false);
        } catch (e) {
            console.log('failed addRoutine');
        }
    }

    const showChildrenDrawer = () => {
        setChangeEnable(true);
    };

    useEffect(() => {
        showDrawer();
        getCurrentTime();
    }, []);

    const handleRoutineName = (e) => {
        console.log(e.target.value);
        routineDevices.name = e.target.value;
        setRoutineDevices({ ...routineDevices });
    }

    const handleRoutineTime = (e) => {
        console.log(e.target.value);
        routineDevices.time = e.target.value;
        setRoutineDevices({ ...routineDevices });
    }

    const week = [
        { label: 'Mon', value: 'monday', key: 1 },
        { label: 'Tue', value: 'tuesday', key: 2 },
        { label: 'Wed', value: 'wednesday', key: 3 },
        { label: 'Thu', value: 'thursday', key: 4 },
        { label: 'Fri', value: 'friday', key: 5 },
        { label: 'Sat', value: 'saturday', key: 6 },
        { label: 'Sun', value: 'sunday', key: 0 },
    ];
    console.log(propertyId);


    return (
        <>
            <Drawer
                className="routine-drawer"
                mask={true}
                width='80%'
                height='90%'
                onClose={hideDrawer}
                placement='bottom'
                visible={visible}
                title="Create Routine"
                extra={
                    <Space>
                        <Button style={{ backgroundColor: ThemeColor.hashhPrimary, border: "none" }} type="primary" onClick={validateRoutine}>
                            Save
                        </Button>
                    </Space>
                }
            >
                <div>
                    <h3>Routine Name</h3>
                    <input
                        style={{
                            outline: 'none',
                            border: 'none',
                            transition: 'all 2s',
                            color: 'black',
                            fontSize: '1.2em',
                            fontWeight: '500',
                            width: '100%'
                        }}
                        autoCorrect="off"
                        placeholder="Enter the Routine name here"
                        type="text"
                        // value={name}
                        onChange={handleRoutineName}
                        autoFocus
                    />
                    <hr />
                    {validationErrors.name && <div style={styles.alert}>Name cannot be empty.</div>}
                    <h5 style={{ color: ThemeColor.hashhPrimary }}>Name can't be edited once created.</h5>
                    <div >
                        <label style={{ fontSize: '16px', marginRight: '10px' }} htmlFor={'timeInput'}>Start Time :</label>
                        <input onClick={() => setTime(true)} type="time" id="timeInput"
                            // value={currentTime}
                            value={time == false ? currentTime : null}
                            onChange={handleRoutineTime}
                            style={{ marginTop: '30px', padding: '5px', border: '2px solid rgb(114,62,216)', borderRadius: '5px', fontSize: ' 16px' }}
                        />
                    </div>
                    {validationErrors.time && <div style={styles.alert}>Time cannot be empty.</div>}
                    <div style={styles.weekContainer}>
                        <div style={{ color: ThemeColor.textColor, marginTop: '40px' }}>Select days to repeat</div>
                        <div style={styles.WeekSelector}>
                            {week.map((data, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            backgroundColor: routineDevices.weekDays.includes(data.key)
                                                ? ThemeColor.hashhPrimary
                                                : ThemeColor.inactiveText,
                                            display: 'flex',
                                            border: "1px solid black",
                                            borderColor: ThemeColor.black,
                                            margin: 12,
                                            width: 40,
                                            height: 40,
                                            borderRadius: 50,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            marginRight: 5,
                                        }}
                                        onClick={() => {
                                            if (routineDevices.weekDays.includes(data.key)) {

                                                const weekDaysFilter = routineDevices.weekDays.filter(el => {
                                                    return el !== data.key;
                                                }
                                                );
                                                routineDevices.weekDays = [...weekDaysFilter]
                                                setRoutineDevices({ ...routineDevices });
                                            } else {
                                                routineDevices.weekDays = [...routineDevices.weekDays, data.key]
                                                setRoutineDevices({ ...routineDevices });
                                            }
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: routineDevices.weekDays.includes(data.key)
                                                    ? ThemeColor.white
                                                    : ThemeColor.black,
                                            }}>
                                            {data.label}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {validationErrors.weekDays && <div style={styles.alert}>Please select at least one day.</div>}
                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                            <button
                                style={styles.changebutton}
                                type='button'
                                onClick={showChildrenDrawer}
                            >Add Change</button>
                            <h4 style={{ marginTop: '40px', color: 'grey', marginLeft: '10px' }}>{[...routineDevices.on, ...routineDevices.off].reduce((total, current) => total + 1, 0)} Device changed</h4>
                        </div>
                        {validationErrors.change && <div style={styles.alert}>Please select at least one change.</div>}
                    </div>
                </div>
            </Drawer>
            {changeEnable ? (
                <SearchDevicesChange
                    changeEnable={changeEnable}
                    change={change}
                    setChangeEnable={setChangeEnable}
                />
            ) : null}
        </>
    )
}

const styles = {
    changebutton: {
        cursor: 'pointer',
        background: ThemeColor.hashhPrimary,
        border: 'none', color: ThemeColor.white,
        padding: '10px',
        textAlign: 'center',
        borderRadius: '10px',
        marginTop: '40px'
    },
    weekContainer: {
        marginVertical: 16,
    },
    WeekSelector: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',
        alignItems: 'center',
        minWidth: '320px'
    },
    alert: {
        fontSize: '12px',
        color: ThemeColor.red
    }
}
export default BottomSheet