import React, { useState } from 'react'
import GateButtonOpenDarkIcon from '../../icons/GateButtonOpenDarkIcon';
import GateButtonCloseDarkIcon from '../../icons/GateButtonCloseDarkIcon';
import RightRunningArrow from '../../common/RightRunningArrow';
import LeftRunningArrow from '../../common/LeftRunningArrow';
import PauseIcon from '../../icons/PauseIcon';
import GateButtonPauseDarkIcon from '../../icons/GateButtonPauseDarkIcon';
import { useUser } from '../../../Context/context';
import { User } from '../../../storage/store';
import { UserService } from '../../../services/UserService';
import { useToggleTop } from '../../common/ToggleTop';

const GC_gate = () => {
    const [gateStatus, setGateStatus] = useState('');

    const {device} = useUser();
    const setToggleTop = useToggleTop();

    if (gateStatus !== '') setTimeout(() => setGateStatus(''), 5000);

    const handleDevice=async(action, rl)=>{
        const propertyId = User.getPropertyId();
        let payload = {
            on:[device.applianceId],
            off:[],
            gc:{
                [device.applianceId]:{
                    action:Number(action),
                    rl:Number(rl),
        }}}
       try{
        console.log( device.deviceType, payload);
        const response = await UserService.stateUpdate(payload, propertyId);
        console.log(response);
        setToggleTop("Success...", 'green');
       }catch(e){
        setToggleTop("Failed !", 'red');
       }
    }

    // console.log(device);

  return (
    <div className='wcm_l1'>
    <div className='wcm_l2' >
        {/* tablet head m1 */}
        <div style={{ fontWeight: '800', display: 'flex', justifyContent: 'space-between', color: '#4B5234' }}>
            <div>Motor 1</div>
            {gateStatus === "open"
                ? <div style={{}}>Opening&nbsp;&nbsp;
                    <RightRunningArrow />
                </div>
                : gateStatus === "close"
                    ? <div style={{}}>Closing&nbsp;&nbsp;
                        <LeftRunningArrow />
                    </div>
                    : ''
            }
            <div>
                {''}
            </div>

        </div>

        {/* Tablet m1 */}
        <div className='wcm_tablet' >
            <div className='gc-icon' onClick={() =>{
                 setGateStatus("open");
                 if(device.deviceType==="SWING_GATE")
                    handleDevice(0, device.pin)
                 else
                    handleDevice(0,1)
            }}>
                <div><GateButtonOpenDarkIcon color={"black"} height={25} width={25} /></div>
            </div>

            <div className='gc-icon' onClick={() =>{
                    setGateStatus("");
                    if(device.deviceType==="SWING_GATE")
                        handleDevice(2, device.pin);
                     else
                        handleDevice(2,3);
                }}>
                <GateButtonPauseDarkIcon color={"black"} height={25} width={25} />
            </div>

            <div className='gc-icon ' onClick={() =>{
                    setGateStatus("close");
                    if(device.deviceType==="SWING_GATE")
                        handleDevice(1, device.pin);
                     else
                        handleDevice(1,2);
                }}>
                <GateButtonCloseDarkIcon color={"black"} height={25} width={25} />
            </div>
        </div>
        <div className='wcm_tablet' style={{ fontWeight: '800' }}>
            <div style={{ textAlign: 'center', flex: '1' }}>Open</div>
            <div style={{ textAlign: 'center', flex: '1' }}>Stop</div>
            <div style={{ textAlign: 'center', flex: '1' }}>Close</div>
        </div>
    </div>
</div>
  )
}

export default GC_gate