import React from 'react'

const RightRunningArrow = () => {
    return (
        <>
            <i className="arrow right element1"></i>
            <i className="arrow right element2"></i>
            <i className="arrow right element3"></i>
        </>
    )
}

export default RightRunningArrow