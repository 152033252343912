import React from 'react'

const ShutterGateOpenLightIcon = () => {
    return (
        <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_ddiiii_417_314)">
                <circle cx="40.5" cy="40.5" r="37.5" fill="#F3F3F3" />
                <circle cx="40.5" cy="40.5" r="37" stroke="#9B9898" />
            </g>
            <circle cx="40.5002" cy="40.4997" r="31.3356" fill="#F3F3F3" />
            <path d="M23 25H58V32.7778H54.1111V56.1111H50.2222V32.7778H30.7778V56.1111H26.8889V32.7778H23V25ZM32.7222 34.7222H48.2778V38.6111H32.7222V34.7222Z" fill="black" />
            <defs>
                <filter id="filter0_ddiiii_417_314" x="-2" y="-2" width="85" height="85" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-1" dy="-1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_417_314" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="1" dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_417_314" result="effect2_dropShadow_417_314" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_417_314" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="5" dy="5" />
                    <feGaussianBlur stdDeviation="6.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0.9 0" />
                    <feBlend mode="normal" in2="shape" result="effect3_innerShadow_417_314" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-5" dy="-5" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.9 0" />
                    <feBlend mode="normal" in2="effect3_innerShadow_417_314" result="effect4_innerShadow_417_314" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="5" dy="-5" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect4_innerShadow_417_314" result="effect5_innerShadow_417_314" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-5" dy="5" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0 0.572549 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect5_innerShadow_417_314" result="effect6_innerShadow_417_314" />
                </filter>
            </defs>
        </svg>
    )
}

export default ShutterGateOpenLightIcon