import { useEffect, useState } from "react";
import { UserService } from "../../../services/UserService";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Label,
  Format,
  Legend,
  Export,
} from "devextreme-react/chart";
import { Card } from "antd";
const YearChart = () => {
  const [data, setData] = useState([]);
  const params = useLocation();
  const device = params?.state?.data;

  const [changeDate, setChangeDate] = useState(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const formattedDate = `${year}`;
    return formattedDate;
  });

  const fetchData = async () => {
    try {
      const res = await UserService.getDoorStatusForYear(
        device.applianceId,
        changeDate
      );
      const result = res.map((item) => ({
        date: moment(item.bucket).format("YYYY-MM"),
        openCount: parseInt(item.open_count),
        closeCount: parseInt(item.close_count),
      }));

      setData(result);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [changeDate]);

  console.log(data);
  return (
    <>
      <Card className="responsive-chart">
        <input
          type="number"
          min="2022"
          max={new Date().getUTCFullYear}
          value={changeDate}
          onChange={(e) => setChangeDate(e.target.value)}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        />
        <Chart
          id="chart"
          title="Year Analysis of Door Sensor"
          dataSource={data}
        >
          <CommonSeriesSettings
            argumentField="date"
            type="bar"
            hoverMode="allArgumentPoints"
            selectionMode="allArgumentPoints"
          >
            <Label visible={true}>
              <Format type="fixedPoint" precision={0} />
            </Label>
          </CommonSeriesSettings>
          <Series valueField="openCount" name="Open-Count" />
          <Series valueField="closeCount" name="Close-Count" />
          <Legend
            verticalAlignment="bottom"
            horizontalAlignment="center"
          ></Legend>
          <Export enabled={true} />
        </Chart>
      </Card>
    </>
  );
};

export default YearChart;
