import React from 'react'
import Wave from 'react-wavify'

const Tank = ({ value, name }) => {
    console.log(value, name);
    return (
        <div>
            <div style={{ display: "flex", justifyContent: 'space-around', alignItems: 'center', height: '50%' }}>
                <div className="tank-status">
                    <Wave fill='#d2baff'
                        paused={false}
                        style={{ display: '', height: '200px' }}
                        options={{
                            height: (value == '2' ? 20 : value == '1' ? 80 : 100),
                            amplitude: 5,
                            speed: 0.2,
                            points: 5
                        }}
                    />
                    {/* <span>
                    </span> */}
                </div>
            </div>
            <p style={{ textAlign: 'center', padding: '20px 0px 0px 0px' }}>{name ?? "Unknown Tank"}</p>

        </div>
    )
}

export default Tank
