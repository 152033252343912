import React from 'react'

const GateButtonOpenDarkIcon = () => {
    return (


        <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_ddiiii_417_266)">
                <circle cx="40.5" cy="40.5" r="37.5" fill="#B2AA95" />
                <circle cx="40.5" cy="40.5" r="37" stroke="#A09576" />
            </g>
            <circle cx="40.5" cy="40.5" r="30.5" fill="#B2AA95" />
            {/* <path d="M35.7273 28V36.3333H32.5455V29.6667H29.3636V36.3333H26.1818V33H23V53H26.1818V49.6667H29.3636V53H32.5455V49.6667H35.7273V53H38.9091V49.6667H42.0909V53H45.2727V49.6667H48.4545V53H51.6364V49.6667H54.8182V53H58V33H54.8182V36.3333H51.6364V29.6667H48.4545V36.3333H45.2727V28H42.0909V36.3333H38.9091V28H35.7273ZM26.1818 39.6667H29.3636V46.3333H26.1818V39.6667ZM32.5455 39.6667H35.7273V46.3333H32.5455V39.6667ZM38.9091 39.6667H42.0909V46.3333H38.9091V39.6667ZM45.2727 39.6667H48.4545V46.3333H45.2727V39.6667ZM51.6364 39.6667H54.8182V46.3333H51.6364V39.6667Z" fill="#584C2B" /> */}
            <path d="M32.5455 52V29H29.3636V35.5714H26.1818V32.2857H23V52H26.1818V48.7143H29.3636V52H32.5455ZM26.1818 45.4286V38.8571H29.3636V45.4286H26.1818ZM54.8182 32.2857V35.5714H51.6364V29H48.4545V52H51.6364V48.7143H54.8182V52H58V32.2857H54.8182ZM54.8182 45.4286H51.6364V38.8571H54.8182V45.4286Z" fill="#584C2B" />
            <defs>
                <filter id="filter0_ddiiii_417_266" x="-1" y="-1" width="83" height="83" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-1" dy="-1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.431373 0 0 0 0 0.411765 0 0 0 0 0.360784 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_417_266" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="1" dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.964706 0 0 0 0 0.921569 0 0 0 0 0.807843 0 0 0 0.3 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_417_266" result="effect2_dropShadow_417_266" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_417_266" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" dy="4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.431373 0 0 0 0 0.411765 0 0 0 0 0.360784 0 0 0 0.9 0" />
                    <feBlend mode="normal" in2="shape" result="effect3_innerShadow_417_266" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-4" dy="-4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.964706 0 0 0 0 0.921569 0 0 0 0 0.807843 0 0 0 0.9 0" />
                    <feBlend mode="normal" in2="effect3_innerShadow_417_266" result="effect4_innerShadow_417_266" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="4" dy="-4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.431373 0 0 0 0 0.411765 0 0 0 0 0.360784 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect4_innerShadow_417_266" result="effect5_innerShadow_417_266" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-4" dy="4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.431373 0 0 0 0 0.411765 0 0 0 0 0.360784 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect5_innerShadow_417_266" result="effect6_innerShadow_417_266" />
                </filter>
            </defs>
        </svg>
    )
}

export default GateButtonOpenDarkIcon