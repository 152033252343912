import React from 'react'

const CurtainIcon = ({ color, height, width }) => {
  return (
    <svg width={width ?? "45"} height={height ?? "50"} viewBox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39 3.42857H0V0H39V3.42857ZM1.77273 36H8.86364C8.86364 30.8571 5.31818 27.4286 5.31818 27.4286C15.9545 20.5714 17.7273 5.14286 17.7273 5.14286H1.77273V36ZM37.2273 5.14286H17.7273C17.7273 5.14286 23 12.5 28 12.5C33 12.5 37.2273 5.14286 37.2273 5.14286Z" fill={color} />
      <path d="M36.9911 22L37.2216 4.97656C37.2216 4.97656 34.5 12.5 33.9911 22C33.9311 23.1206 36.9911 22 36.9911 22Z" fill={color ?? 'black'} />
    </svg>
  )
}

export default CurtainIcon