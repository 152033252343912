import API from "../api/manager";
import config from "../api/config";

const getProperty = () => {
  return API({
    method: "GET",
    url: config.urls.getProperty,
  });
};
const getRooms = (id) => {
  return API({
    method: "GET",
    url: config.urls.getRooms + "/" + id + "/rooms-v2",
  });
};
const getConfig = (id) => {
  return API({
    method: "GET",
    url: config.urls.getConfigById + "/" + id,
  });
};
const updateConfig = (data, propertyId) => {
  return API({
    method: "PUT",
    url: config.urls.updateConfig + `/${propertyId}`,
    data,
  });
};
// const getRoutineForApplianceId = (propertyId, applianceId) =>{
//   return API({
//     method: "GET",
//     url: `routine/${propertyId}/${applianceId}`,
//   })
// }

//Door sensor api

const getDoorStatusForDay = (deviceId, dates) => {
  return API({
    method: "GET",
    url:
      config.urls.getDoorStatusForDay + `/bcddc2f0e665?requestDate=2023-10-27`,
  });
};

const getDoorStatusForMonth = (deviceId, dates) => {
  return API({
    method: "GET",
    url: `${config.urls.getDoorStatusForMonth}/${deviceId}?requestDate=${dates}`,
  });
};

const getDoorStatusForYear = (deviceId, dates) => {
  return API({
    method: "GET",
    url: `${config.urls.getDoorStatusForYear}/${deviceId}?requestDate=${dates}`,
  });
};

const getDeviceTypes = (id) => {
  return API({
    method: "GET",
    url: config.urls.getDeviceTypes + "/" + id + "/device/types",
  });
};
const stateUpdate = (data, propertyId) => {
  return API({
    method: "POST",
    url: config.urls.updateState + `/${propertyId}`,
    data,
  });
};
const getLastStatebydeviceId = (propertyId, deviceId) => {
  return API({
    method: "GET",
    url: config.urls.getLastState + `/${propertyId}/deviceId/${deviceId}`,
  });
};
const getLastStateByRoom = (propertyId, floor, room) => {
  return API({
    method: "GET",
    url:
      config.urls.getLastState + `/${propertyId}/floor/${floor}/room/${room}`,
  });
};
const getLastStateByFloorRoomId = (propertyId, floorId, roomId) => {
  return API({
    method: "GET",
    url:
      config.urls.getLastState +
      `/${propertyId}/floor-id/${floorId}/room-id/${roomId}`,
  });
};
const getLastStateByProperty = (propertyId) => {
  return API({
    method: "GET",
    url: config.urls.getLastState + `/${propertyId}`,
  });
};
const fav = (data, propertyId) => {
  return API({
    method: "PUT",
    url: config.urls.getDeviceTypes + `/${propertyId}` + "/favourite",
    data,
  });
};
const updateDevice = (data, propertyId) => {
  return API({
    method: "PUT",
    url: config.urls.getDeviceTypes + `/${propertyId}` + "/appliance",
    data,
  });
};
const registerDevice = (data) => {
  return API({
    method: "POST",
    url: config.urls.registerDevice + "/register",
    data,
  });
};

const createProperty = (data) => {
  return API({
    method: "POST",
    url: config.urls.getConfigById,
    data,
  });
};

const createFloor = (propertyId, data) => {
  return API({
    method: "POST",
    url: config.urls.getConfigById + "/" + propertyId + "/floor",
    data,
  });
};

const deleteFloor = (propertyId, data) => {
  return API({
    method: "DELETE",
    url: config.urls.getConfigById + "/" + propertyId + "/floor",
    data,
  });
};

const createRoom = (propertyId, data) => {
  return API({
    method: "POST",
    url: config.urls.getConfigById + "/" + propertyId + "/room",
    data,
  });
};

const deleteRoom = (propertyId, data) => {
  return API({
    method: "DELETE",
    url: config.urls.getConfigById + "/" + propertyId + "/room",
    data,
  });
};

const createDevice = (propertyId, data) => {
  return API({
    method: "POST",
    url: config.urls.getConfigById + "/" + propertyId + "/device",
    data,
  });
};

const deleteDevice = (propertyId, deviceId) => {
  return API({
    method: "DELETE",
    url: config.urls.deleteDevice + "/" + propertyId + "/remove/" + deviceId,
  });
};

const getTimeline = (deviceId, dates) => {
  return API({
    method: "GET",
    url: `${config.urls.getTimeline}/${deviceId}/?from=${dates[0]}&to=${dates[1]}`,
  });
};

const getKwhData = (deviceId) => {
  return API({
    method: "GET",
    url: `${config.urls.getKwhByMonth}/${deviceId}`,
  });
};

const getWattsLastOneHourData = (deviceId) => {
  return API({
    method: "GET",
    url: `${config.urls.getWattsByLastOneHour}/${deviceId}`,
  });
};

const getKwhWeekData = (deviceId) => {
  return API({
    method: "GET",
    url: `${config.urls.getKwhByWeek}/${deviceId}`,
  });
};

const getRoutine = (propertyId) => {
  return API({
    method: "GET",
    url: config.urls.routine + "/" + propertyId,
  });
};

const addRoutine = (propertyId, data) => {
  return API({
    method: "POST",
    url: config.urls.routine + "/" + propertyId,
    data,
  });
};

const updateRoutine = (routineId, data) => {
  return API({
    method: "PUT",
    url: config.urls.routine + "/" + routineId,
    data,
  });
};

const deleteRoutine = (routineId) => {
  return API({
    method: "DELETE",
    url: config.urls.routine + "/" + routineId,
  });
};

const enableRoutine = (routineId, data) => {
  return API({
    method: "PUT",
    url: config.urls.routine + "/" + routineId + "/change-state",
    data,
  });
};

const getRoutineByApplianceId = (propertyId, applianceId)=>{
  return API({
    method:"GET",
    url: config.urls.routine + "/" + propertyId + "/" + applianceId,
  })
}

//Rule Engine

const getRulesByPropertyID = (propertyId) => {
  return API({ method: "GET", url: config.urls.rule + "/" + propertyId });
};

const getRuleByRuleID = (propertyId, ruleId) => {
  return API({
    method: "GET",
    url: config.urls.rule + "/" + propertyId + "/id/" + ruleId,
  });
};

const addRules = (propertyId, data) => {
  return API({
    method: "POST",
    url: config.urls.rule + "/" + propertyId,
    data,
  });
};

const updateRules = (propertyId, ruleId, data) => {
  return API({
    method: "PUT",
    url: config.urls.rule + "/" + propertyId + "/id/" + ruleId,
    data,
  });
};

const deleteRules = (propertyId, ruleId) => {
  return API({
    method: "DELETE",
    url: config.urls.rule + "/" + propertyId + "/id/" + ruleId,
  });
};

const enableRule = (propertyId, ruleId, data) => {
  return API({
    method: "PUT",
    url:
      config.urls.rule + "/" + propertyId + "/id/" + ruleId + "/change-state",
    data,
  });
};

const getHIKCodes = (propertyId) => {
  return API({
    method: "GET",
    url: config.urls.getHIKCodes(propertyId),
  });
};

const getHIKAccessToken = (propertyId) => {
  return API({
    method: "GET",
    url: config.urls.getHIKAccessToken(propertyId),
  });
};

const addHIKDevice = (propertyId, postbody) => {
  return API({
    method: "POST",
    url: config.urls.addHIKDevice(propertyId),
    data: postbody,
  });
};

const deleteHIKDevice = (propertyId, deviceId) => {
  return API({
    method: "DELETE",
    url: config.urls.deleteHIKDevice(propertyId, deviceId),
  });
};

const getRemoteCategories = () => {
  return API({
    method: "GET",
    url: config.urls.getRemoteCategories(),
  });
};
const getRemoteBrands = (categoryId, searchText, from, count) => {
  return API({
    method: "GET",
    url: config.urls.getRemoteBrands(categoryId, searchText, from, count),
  });
};

const getRemotes = (categoryId, brandId) => {
  return API({
    method: "GET",
    url: config.urls.getRemotes(categoryId, brandId),
  });
};

const decodeRemote = (postbody) => {
  return API({
    method: "POST",
    url: config.urls.decodeRemote,
    data: postbody,
  });
};

const decodeRemoteById = (propertyId, remoteId) => {
  return API({
    method: "GET",
    url: config.urls.decodeRemoteById(propertyId, remoteId),
  });
};

const addRemoteToProperty = (propertyId, postbody) => {
  return API({
    method: "POST",
    url: config.urls.addRemote(propertyId),
    data: postbody,
  });
};

const getRemoteData = (propertyId, remoteId) => {
  return API({
    method: "GET",
    url: config.urls.getRemoteData(propertyId, remoteId),
  });
};

const deleteRemote = (propertyId, remoteId) => {
  return API({
    method: "DELETE",
    url: config.urls.deleteRemote(propertyId, remoteId),
  });
};

const renameRemote = (propertyId, postbody) => {
  return API({
    method: "PUT",
    url: config.urls.renameRemote(propertyId),
    data: postbody,
  });
};

const updateRemoteKey = (propertyId, remoteId, postbody) => {
  return API({
    method: "PUT",
    url: config.urls.updateRemoteKey(propertyId, remoteId),
    data: postbody,
  });
};

const updateFcmToken = (data, propertyId) => {
  return API({
    method: "POST",
    url: config.urls.user + `/fcmToken`,
    data,
  });
};

const deleteFcmToken = (data, propertyId) => {
  return API({
    method: "DELETE",
    url: config.urls.user + `/fcmToken`,
    data,
  });
};

const userSearch = (searchQuery) => {
  return API({
    method: "GET",
    url: `${config.urls.user}/list`,
    params: { search: searchQuery },
  });
};

const getSharedUsers = (propertyId) => {
  return API({
    method: "GET",
    url: `${config.urls.getProperty}/${propertyId}/shared`,
  });
};

const inviteUser = (data, propertyId) => {
  return API({
    method: "POST",
    url: `${config.urls.getProperty}/${propertyId}/share`,
    data,
  });
};

const updateSharing = (data, propertyId) => {
  return API({
    method: "PUT",
    url: `${config.urls.getProperty}/${propertyId}/share`,
    data,
  });
};

const deleteSharing = (propertyId, permissionId) => {
  return API({
    method: "DELETE",
    url: `${config.urls.getProperty}/${propertyId}/share/${permissionId}`,
  });
};

export const UserService = {
  getProperty,
  getConfig,
  updateConfig,
  getRooms,
  getDeviceTypes,
  stateUpdate,
  getLastStatebydeviceId,
  getLastStateByRoom,
  getLastStateByFloorRoomId,
  getLastStateByProperty,
  fav,
  updateDevice,
  registerDevice,
  createProperty,
  createFloor,
  deleteFloor,
  createRoom,
  deleteRoom,
  createDevice,
  getTimeline,
  getKwhData,
  getWattsLastOneHourData,
  getKwhWeekData,
  deleteDevice,
  getRoutine,
  addRoutine,
  updateRoutine,
  deleteRoutine,
  enableRoutine,
  getRulesByPropertyID,
  getRuleByRuleID,
  addRules,
  updateRules,
  deleteRules,
  enableRule,
  getHIKCodes,
  getHIKAccessToken,
  addHIKDevice,
  deleteHIKDevice,
  getRemoteCategories,
  getRemoteBrands,
  getRemotes,
  decodeRemote,
  decodeRemoteById,
  addRemoteToProperty,
  getRemoteData,
  deleteRemote,
  renameRemote,
  updateRemoteKey,
  updateFcmToken,
  deleteFcmToken,
  userSearch,
  getSharedUsers,
  inviteUser,
  updateSharing,
  deleteSharing,
  getDoorStatusForDay,
  getDoorStatusForYear,
  getDoorStatusForMonth,
  getRoutineByApplianceId
};
