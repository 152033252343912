const ThemeColor = {
    textColor: '#000000',
    inactiveText: 'black',
    white: 'white',
    whitesmoke: '#e3e3e3',
    black: 'black',
    red: 'red',
    green: 'green',
    darkgreen: '#065F05',
    darkred: '#B30000',
    lightgrey:'#D9D9D9',
    disabled: '#D8D8D8',
    alertSuccess: 'rgb(71,177,116)',
    alertError: 'rgb(255,85,85)',
    overlay: 'rgba(0,0,0,0.4)',
    hamColor: 'white',
    header: 'black',
    slateBlue: 'rgba(114,62,216, 0.5)',
    activeText: 'white',
    inactiveText: 'rgba(255, 255, 255, 0.6)',
    blueRibbon: '#0a62f1',
    assistantHead: 'rgb(66, 133, 244)',
    bulbPrimary: 'rgba(249, 229, 123, 1)',
    bulbSecondary: 'rgba(255, 204, 59, 1)',
    socketSecondary: '#4c64df',
    socketPrimary: '#526eff',
    fanSecondary: '#7448e0',
    fanPrimary: '#8d48fe',
    hashhPrimary: 'rgb(114,62,216)',
    hashhPrimaryHalf: 'rgba(114,62,216, 0.5)',
    hashhPrimaryQuarter: 'rgba(114,62,216, 0.25)',
    switchPrimary: '#FF4B2B',
    switchSecondary: '#FF416C',
    switchPrimary: '#6e828e',
    switchSecondary: 'white',
    doorPrimary: '#00F260',
    doorSecondary: '#0575E6',
    pirPrimary: '#8d48fe',
    pirSecondary: '#0575E6',
    gasPrimary: '#8d48fe',
    gasSecondary: '#0575E6',
    smokePrimary: '#8d48fe',
    smokeSecondary: '#0575E6',
    off: '#fe4550',
    on: '#47e888',
    // quickActions: ['#f06777', '#51d5b0', ],
    // quickActions: ['#f06777', '#51d5b0', '#95a0fa','#0387CF','#A95EA3','#8D3E4C','#DE7C4E','#F03C61','#00AE7D',],
    quickActions:['#f06777', '#51d5b0', '#95a0fa'],
    lightgray: '#bdbdbd',
    // swtiches
    bulbPrimary1: 'rgba(252, 224, 84, 1)',
    bulbSecondary1: 'rgba(255, 204, 59, 1)',
    bulbPrimary: '#6e828e',
    bulbSecondary: '#6e828e',
    socketSecondary1: 'rgba(63, 92, 238, 1)',
    socketPrimary1: 'rgba(51, 85, 255, 1)',
    socketPrimary: '#6e828e',
    SocketSecondary: '#6e828e',
    fanSecondary1: 'rgba(90, 33, 232, 1)',
    fanPrimary1: 'rgba(90, 33, 232, 1)',
    switchPrimary1: 'rgba(224, 0, 0, 1)',
    switchSecondary1: 'rgba(224, 0, 0, 1)',
    switchPrimary: '#6e828e',
    switchSecondary: '#6e828e',
    switchTextColor: '#e6eaec',
    bgColor: '#F1F9FF'
};

export default ThemeColor;