import React from "react";
import { Link } from "react-router-dom";
import { Layout, Card } from "antd";
import Hashh from "../assets/images/Hashh.png";
import { useEffect } from "react";
import { useUser } from "../Context/context";
import '../assets/styles/main.css'

const { Content, Footer } = Layout;

function SelectEcoSystem() {
  const { getProperty } = useUser();

  useEffect(() => {
    getProperty();
  }, []);

  return (
    <>
      <div className="selectEcosystem">
        <h2 className="selectEcosystemHeading">Select Your EcoSystem</h2>

        <div className="selectEcosystemCardContainer">

        <Link to="/Dashboard" style={{ color: "black" }}>
        <div className="ecosystemCard">
              <img
                src={Hashh}
                className="ecosystemLogo"
                alt="Hashh "
              />
            <p
              className="ecosystemTitle"
            >
              HASHh Connect
            </p>
          </div>
          </Link>

          {/* <Link to="/Property" style={{ color: "black" }}> */}
        <div className="ecosystemCard">
              <img
                src={Hashh}
                className="ecosystemLogo"
                alt="Hashh "
              />
            <p
              className="ecosystemTitle"
            >
              HASHh Industry
            </p>
          </div>
          {/* </Link> */}

          {/* <Link to="/Property" style={{ color: "black" }}> */}
        <div className="ecosystemCard">
              <img
                src={Hashh}
                className="ecosystemLogo"
                alt="Hashh "
              />
            <p
              className="ecosystemTitle"
            >
              HASHh Agro
            </p>
          </div>
          {/* </Link> */}

        </div>

        <p className="copyright">
          Copyright © 2021 by <a href="#pablo">HASHh Automations</a>.
        </p>
      </div>
      

{/* 
      <Layout style={{ marginTop: "70px" }}>
        <center>
          <b
            style={{
              color: "black",
              fontSize: "26px",
              marginTop: "130px",
              marginBottom: "100px",
              marginRight: "80px",
              marginLeft: "100px",
            }}
          >
            Select your EcoSystem
          </b>
        </center>

        <Content style={{ display: "inline-flex", marginTop: "100px" }}>
          <Link to="/Property" style={{ color: "black" }}>
            <Card
              hoverable
              style={{
                width: 400,
                borderRadius: "15px",
                margin: "10px",
                marginLeft: "90px",
              }}
            >
              <img
                src={Hashh}
                alt="Hashh "
                style={{
                  width: "130px",
                  height: "130px",
                  margin: "10px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              />
              <b
                style={{
                  fontSize: "20px",
                  color: "black",
                  margin: "10px",
                  float: "right",
                  marginTop: "60px",
                }}
              >
                HASHh Connect
              </b>
            </Card>
          </Link>
          <Link to="/SelectEcoSystem" style={{ color: "black" }}>
            <Card
              hoverable
              style={{
                width: 400,
                borderRadius: "15px",
                margin: "10px",
                marginLeft: "20px",
              }}
            >
              <img
                src={Hashh}
                alt="Hashh "
                style={{
                  width: "130px",
                  height: "130px",
                  margin: "10px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              />
              <b
                style={{
                  fontSize: "20px",
                  color: "black",
                  margin: "10px",
                  float: "right",
                  marginTop: "60px",
                }}
              >
                HASHh Industry
              </b>
            </Card>
          </Link>
          <Link to="/SelectEcoSystem" style={{ color: "black" }}>
            <Card
              hoverable
              style={{
                width: 400,
                borderRadius: "15px",
                margin: "10px",
                marginLeft: "20px",
              }}
            >
              <img
                src={Hashh}
                alt="Hashh "
                style={{
                  width: "130px",
                  height: "130px",
                  margin: "10px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              />
              <b
                style={{
                  fontSize: "20px",
                  color: "black",
                  margin: "10px",
                  float: "right",
                  marginTop: "60px",
                }}
              >
                HASHh Agro
              </b>
            </Card>
          </Link>
        </Content>

        <Footer style={{ marginTop: "210px" }}>
          <center>
            <p className="copyright">
              {" "}
              Copyright © 2021 by <a href="#pablo">HASHh Automations</a>.{" "}
            </p>
          </center>
        </Footer>
      </Layout> */}
    </>
  );
}
export default SelectEcoSystem;
