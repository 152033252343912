import React, { useState } from 'react'
import PauseIcon from '../../icons/PauseIcon'
import CurtainCloseIcon from '../../icons/CurtainCloseIcon'
import CurtainDoubleIcon from '../../icons/CurtainDoubleIcon'
import LeftRunningArrow from '../../common/LeftRunningArrow'
import RightRunningArrow from '../../common/RightRunningArrow'
import { useUser } from '../../../Context/context'
import { UserService } from '../../../services/UserService'
import { User } from '../../../storage/store'
import { useToggleTop } from '../../common/ToggleTop'

const W2CM_1 = () => {
    const [curtain1, setCurtain1] = useState('')
    const [curtain2, setCurtain2] = useState('');
    const {device} = useUser();
    const setToggleTop = useToggleTop();

    const handleDevice=async(id, value)=>{
        const propertyId = User.getPropertyId();
        let payload = {
            on:[device.applianceId],
            off:[],
            action:{
                [device.applianceId]:{
                    id:id,
                    trait:901,
                    value:value
        }}}
       try{
        const response = await UserService.stateUpdate(payload, propertyId);
        console.log(response);
        setToggleTop("Success...", 'green');
       }catch(e){
        setToggleTop("Failed !", 'red');
       }
    }
    if(curtain1!=='') setTimeout(()=>setCurtain1(''), 5000);
    if(curtain2!=='') setTimeout(()=>setCurtain2(''), 5000);
    console.log(device);
    return (
        <div className='wcm_l1'>
            <div className='wcm_l2' >
                {/* tablet head m1 */}
                <div style={{ fontWeight: '800', display: 'flex', justifyContent: 'space-between', color: '#4B5234' }}>
                    <div>Motor 1</div>
                    {curtain1 === "open"
                        ? <div style={{}}>Opening&nbsp;&nbsp;
                            <RightRunningArrow/>
                        </div>
                    : curtain1 === "close"
                        ? <div style={{}}>Closing&nbsp;&nbsp;
                            <LeftRunningArrow/>
                        </div>
                        : ''
                    }
                    <div>
                        {''}
                    </div>

                </div>

                {/* Tablet m1 */}
                <div className='wcm_tablet' >
                    <div className='wcm-icon wcm-left-icon' onClick={()=>{
                        setCurtain1("open");
                        handleDevice(1,1)
                    }}>
                        <div><CurtainDoubleIcon color={"black"} height={25} width={25} /></div>
                    </div>

                    <div className='wcm-icon' onClick={()=>{
                        setCurtain1("");
                        handleDevice(1,0);
                    }}>
                        <PauseIcon color={"black"} height={25} width={25} />
                    </div>

                    <div className='wcm-icon wcm-right-icon' onClick={()=>{
                        setCurtain1("close");
                        handleDevice(2,1)
                    }}>
                        <CurtainCloseIcon color={"black"} height={25} width={25} />
                    </div>
                </div>
            </div>

            {/* open close stop */}
            <div className='wcm_tablet' style={{fontWeight: '800'}}>
                <div style={{textAlign:'center', flex:'1'}}>Open</div>
                <div style={{textAlign:'center', flex:'1'}}>Stop</div>
                <div style={{textAlign:'center', flex:'1'}}>Close</div>
            </div>

            {/* motar 2 */}
            <div className='wcm_l2'>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '5px',

                    }}
                >
                    <div className='wcm-icon wcm-left-icon' onClick={()=>{
                        setCurtain2("open");
                        handleDevice(3,1)
                    }}>
                        <CurtainDoubleIcon color={"black"} height={25} width={25} />
                    </div>

                    <div className='wcm-icon' onClick={()=>{
                        setCurtain2("");
                        handleDevice(4,0)
                    }}>
                        <PauseIcon color={"black"} height={25} width={25} />
                    </div>

                    <div className='wcm-icon wcm-right-icon' onClick={()=>{
                        setCurtain2('close');
                        handleDevice(4,1)
                    }}>
                        <CurtainCloseIcon color={"black"} height={25} width={25} />
                    </div>
                </div>
                <div style={{ fontWeight: '800', display: 'flex', justifyContent: 'space-between', color: '#4B5234' }}>
                    <div>Motor 2</div>
                    {curtain2 === "open"
                        ? <div style={{}}>Opening&nbsp;&nbsp;
                            <RightRunningArrow/>
                        </div>
                    : curtain2 === "close"
                        ? <div style={{}}>Closing&nbsp;&nbsp;
                            <LeftRunningArrow/>
                        </div>
                        : ''
                    }
                    <div>
                        {''}
                    </div>

                </div>

            </div>
        </div>
    )
}

export default W2CM_1