import React from 'react'
import { useUser } from '../../Context/context'
import { UserService } from '../../services/UserService';
import { User } from '../../storage/store';
import { useToggleTop } from '../common/ToggleTop';

const SwitchSettings = () => {
  const { device } = useUser();
  const setToggleTop = useToggleTop();

  const handleDeviceDelete=async()=>{
   try{
    const propertyId = User.getPropertyId();
    const response = await UserService.deleteDevice(propertyId, device.deviceId);
    setToggleTop("Success", 'green');
   }catch(e){
    setToggleTop("Failed", 'red');
   }
  }
  console.log(device);
  return (
    <div style={{
      display:'flex',
      flexDirection:'column',
      padding:'10px 0px 0px 20px',
      gap:'30px'
    }}>

      <div>
        <div className='switchSettings-name'>Name</div>
        <div>
          <input 
            type='text' 
            className='switchSettings-input'
            defaultValue={device?.name}
          />
        </div>
      </div>

      <div>
        <div className='switchSettings-name'>Room</div>
        <div>
          <input 
            type='text' 
            className='switchSettings-input'
            defaultValue={device?.room}
          />
        </div>
      </div>

      <div>
        <div className='switchSettings-name'>Floor</div>
        <div>
          <input 
            type='text' 
            className='switchSettings-input'
            defaultValue={device?.floor}
          />
        </div>
      </div>

      <div>
        <div className='switchSettings-name'>Device Type</div>
        <div>
          <input 
            type='text' 
            className='switchSettings-input'
            defaultValue={device?.deviceType}
            readOnly={true}
            style={{
              backgroundColor:'rgb(238 235 203 / 85%)'
            }}
          />
        </div>
      </div>

      <div>
        <div className='switchSettings-name'>Device IP Voltage</div>
        <div>
          <input 
            type='text' 
            className='switchSettings-input'
            defaultValue={device?.meta?.voltage}
          />
        </div>
      </div>

      <div>
        <div className='switchSettings-name'>Device IP Current</div>
        <div>
          <input 
            type='text' 
            className='switchSettings-input'
            defaultValue={device?.meta?.current}
          />
        </div>
      </div>

      <p style={{fontSize:'20px', fontWeight:'100'}}>Power: {device?.meta?.voltage * device?.meta?.current} W</p>

      <div style={{
        display:'flex',
        justifyContent:'center',
        textAlign:'center'
      }}>
        <div
        onClick={handleDeviceDelete}
          style={{
            // border:'1px solid red',
            padding:'5px 50px',
            fontSize:'20px',
            color:'red',
            cursor:'pointer'
          }}
        >DELETE SWITCH</div>
      </div>
      
    </div>
  )
}

export default SwitchSettings